import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";
import useToast from "components/atoms/toast/useToast";

import FormInput from "components/molecules/FormInput";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { genericEnterKeyDown } from "utils/validation";

const Container = styled.div`
    width: 100%;
`;

function TagEditModal(props) {
    const { item, mutation, exitModal } = props;

    const { setLoading } = useLoading();
    const { setToast } = useToast();

    const inputRef = useRef(null);

    const [tagName, setTagName] = useState(item.name || "");

    const handleApply = () => {
        mutation.mutate(
            { name: tagName, ...(item.index && { id: item.index }) },
            {
                onSuccess: () => {
                    if (item.index) {
                        setToast("태그가 수정되었습니다.", "info");
                    } else {
                        setToast("태그가 추가되었습니다.", "info");
                    }
                    setLoading(false);
                    exitModal();
                },
                onError: (error, variables) => {
                    setLoading(false);
                    Alert("warn", "에러", error.response.data.name);
                },
            },
        );
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <Container>
            <FormInput
                labelDp="none"
                placeholder="Tag Name"
                value={tagName}
                onChange={(e) => setTagName(e.target.value)}
                onKeyDown={(e) => {
                    genericEnterKeyDown(e, () => {
                        handleApply();
                    });
                }}
                ref={inputRef}
            />
            <AbstractModalFooter
                leftBtnText={"Cancle"}
                rightBtnText={item.index ? "Rename" : "Create"}
                leftOnClick={props.exitModal}
                rightOnClick={() => {
                    handleApply();
                }}
            />
        </Container>
    );
}

export default TagEditModal;
