import React, { useEffect, useState } from "react";
import { SketchPicker } from "react-color";

import styled from "styled-components";

import Icon from "components/atoms/Icon";

const Item = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    border-radius: 4px;
    white-space: nowrap;
    background-color: ${(props) => (props.disabled ? "var(--color-Disabled)" : "transparent")};
    color: ${(props) => (props.disabled ? "var(--color-Grey2)" : "var(--color-NormalBlack)")};
    &:hover {
        background-color: ${(props) => (props.disabled ? "var(--color-Disabled)" : "var(--color-Base1)")};
    }

    ${(props) =>
        props.divider &&
        `
        margin: 8px 0;
        padding: 0;
        width: 100%;
        height: 2px;
        max-height: 1px;
        background-color: var(--color-Line);
        cursor: default;
        overflow: hidden;

        &:hover {
            background-color: var(--color-Line);
        }

        p {
            display: none;
        }
    `}

    ${(props) =>
        props.date &&
        `
        margin: 12px 0 4px 0;
        padding: 0;
        width: 100%;
        cursor: initial;

        &:hover {
            background-color: transparent;
        }
    `}
`;

const ItemIconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 12px 0 0;
    width: 14px;
    height: 14px;
`;

const ItemNameText = styled.p`
    margin: 0;
    width: calc(100% - 18px);
    min-width: ${(props) => props.minWidth || "160px"};
    font-size: 13px;
    font-weight: 400;
    color: var(--color-NormalBlack);
`;

const SubmenuIconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 4px;
    width: 12px;
    height: 12px;
`;

const SubMenuContainer = styled.div`
    position: absolute;
    top: 0;
    left: 100%;
    padding: 10px;
    background-color: var(--color-White);
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.24);
    display: block;
`;

const MenuItem = (props) => {
    const [hover, setHover] = useState(false);

    const handleMouseEnter = () => {
        setHover(true);
    };

    const handleMouseLeave = () => {
        setHover(false);
    };

    // 서브메뉴 항목에 대한 클릭 이벤트 핸들러
    const handleItemClick = (e) => {
        if (props.item.disabled) return;
        e.stopPropagation();
        if (props.item.action) {
            props.item.action();
            props.onClose();
        }
    };

    return (
        <Item
            divider={props.item.type === "divider"}
            date={props.item.type === "date"}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={handleItemClick}
            disabled={props.item.disabled}
        >
            {props.item.iconName && (
                <ItemIconWrap>
                    <Icon
                        name={props.item.iconName}
                        size={"14"}
                        fill={props.item.disabled ? "var(--color-Grey1)" : "var(--color-Black)"}
                    />
                </ItemIconWrap>
            )}
            {<ItemNameText minWidth={props.minWidth}>{props.item.name}</ItemNameText>}
            {props.item.checked && <Icon name={"check"} />}

            {props.item.subItems && props.item.subItems.length > 0 && (
                <SubmenuIconWrap>
                    <Icon name={"arrowRight"} />
                </SubmenuIconWrap>
            )}
            {props.item.subItems && hover && (
                <SubMenuContainer>
                    {props.item.subItems.map((subItem, index) => (
                        <MenuItem key={index} item={subItem} depth={props.depth + 1} onClose={props.onClose} />
                    ))}
                </SubMenuContainer>
            )}
        </Item>
    );
};

export default MenuItem;
