import React from "react";
import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

import Image from "components/atoms/Image";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import { useDevice } from "utils/device";

const Container = styled.div`
    box-sizing: border-box;
    padding: 40px 40px 48px 40px;
    width: 100%;
    background-color: var(--color-Black);
`;

const TopLine = styled.div`
    margin-bottom: 40px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;

    ${(props) =>
        props.device.isPC &&
        css`
            flex-direction: row;
            justify-content: space-between;
            align-items: flex-start;
        `};
`;

const LeftSide = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 40px 0 0 0;

    ${(props) =>
        props.device.isPC &&
        css`
            flex-direction: row;
            margin: 0;
        `};
`;

const InfoSection = styled.div`
    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            margin-right: 80px;
        `};
`;

const ContactSection = styled.div`
    margin: 40px 0 0 0;

    ${(props) =>
        props.device.isPC &&
        css`
            margin: 0;
        `};
`;

const BottomLine = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            flex-direction: row;
            align-items: center;
        `};
`;

const TermsWrap = styled.div`
    display: flex;
    align-items: center;
`;

const secTitleStyle = {
    size: "small",
    margin: "0 0 20px 0",
    fontWeight: "bold",
    color: "var(--color-Grey1)",
};

const mobileInfoSecStyle = {
    size: "xsmall",
    margin: "4px 0 0 0",
    color: "var(--color-Grey1)",
};

const termTextBtnTabletOrPCStyle = {
    size: "regular",
    margin: "0 16px 0 0",
    fontWeight: "medium",
    color: "var(--color-Grey1)",
};

const termTextBtnMobileStyle = {
    size: "xsmall",
    margin: "0 8px 0 0",
    fontWeight: "medium",
    color: "var(--color-Grey1)",
};

function PageFooter(props) {
    const device = useDevice();
    const termTextBtnStyle = device.isTabletOrPC ? termTextBtnTabletOrPCStyle : termTextBtnMobileStyle;

    return (
        <Container>
            <TopLine device={device}>
                <LeftSide device={device}>
                    {device.isTabletOrPC ? (
                        <InfoSection device={device}>
                            <TitleText {...secTitleStyle}>COMPANY INFO</TitleText>
                            <GeneralText size={"regular"} color={"var(--color-Grey2)"}>
                                상호명 : (주) 데이탄소프트 | 사업자번호 : 326-88-01855 | 통신판매업 신고번호 : 제
                                2022-제주연동-0076-호
                                <br />
                                주소 : 제주특별자치도 제주시 도령북길 31, 2층 데이탄소프트 | 대표이사 : 명재석
                            </GeneralText>
                        </InfoSection>
                    ) : (
                        <InfoSection device={device}>
                            <TitleText {...secTitleStyle}>COMPANY INFO</TitleText>
                            <GeneralText {...mobileInfoSecStyle}>상호명 : (주) 데이탄소프트</GeneralText>
                            <GeneralText {...mobileInfoSecStyle}>사업자번호 : 326-88-01855</GeneralText>
                            <GeneralText {...mobileInfoSecStyle}>
                                통신판매업 신고번호 : 제 2022-제주연동-0076-호
                            </GeneralText>
                            <GeneralText {...mobileInfoSecStyle}>
                                주소 : 제주특별자치도 제주시 도령북길 31, 2층 데이탄소프트
                            </GeneralText>
                            <GeneralText {...mobileInfoSecStyle}>대표이사 : 명재석</GeneralText>
                        </InfoSection>
                    )}
                    <ContactSection device={device}>
                        <TitleText {...secTitleStyle}>CONTACT</TitleText>
                        {device.isTabletOrPC ? (
                            <GeneralText size={"regular"} color={"var(--color-Grey2)"}>
                                전화번호 : 064-713-9844
                                <br />
                                이메일 주소 : prep@datansoft.com
                            </GeneralText>
                        ) : (
                            <>
                                <GeneralText {...mobileInfoSecStyle}>전화번호 : 064-713-9844</GeneralText>
                                <GeneralText {...mobileInfoSecStyle}>이메일 주소 : prep@datansoft.com</GeneralText>
                            </>
                        )}
                    </ContactSection>
                </LeftSide>
                <Image name={"footerLogo"} />
            </TopLine>
            <BottomLine device={device}>
                <TermsWrap>
                    <Link to={"/terms-of-service"} target="_blank">
                        <GeneralText {...termTextBtnStyle}>이용약관</GeneralText>
                    </Link>
                    <Link to={"/privacy-policy"} target="_blank">
                        <GeneralText {...termTextBtnStyle}>개인정보 처리방침</GeneralText>
                    </Link>
                </TermsWrap>
                <GeneralText
                    size={device.isTabletOrPC ? "regular" : "xsmall"}
                    margin={device.isTabletOrPC ? "initial" : "10px 0 0 0"}
                    fontWeight={"medium"}
                    color={"var(--color-Grey2)"}
                >
                    (C) DataNSoft Inc. All right reserved.
                </GeneralText>
            </BottomLine>
        </Container>
    );
}

export default PageFooter;
