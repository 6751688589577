import { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";

import { genericEnterKeyDown } from "utils/validation";

const Container = styled.div`
    width: 100%;
`;

const Input = styled.input`
    padding: 2px;
    text-align: justify;
    width: 100%;
    max-width: 1000px;
    line-height: 1.4;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--color-Black);
    background-color: ${({ isEditable }) => (isEditable ? "var(--color-Base1)" : "var(--color-White)")};
    border: ${({ isEditable }) => (isEditable ? "solid 1px var(--color-InputLine)" : "solid 1px var(--color-White)")};
    border-radius: 4px;
    outline: #dce1e7;
    resize: none;
    cursor: text;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &:hover {
        background-color: ${({ isEditable }) => (isEditable ? "var(--color-Base1)" : "var(--color-Base1)")};
    }

    &::placeholder {
        color: var(--color-Grey2);
    }
`;

function ReferenceDetailTitleInput(props) {
    const { biblioText } = props;
    const [isEditable, setIsEditable] = useState(false);
    const [title, setTitle] = useState("");
    const [oldValue, setOldValue] = useState("");
    const inputRef = useRef(null);
    const isEditableRef = useRef(false);

    useEffect(() => {
        if (props.value) {
            setTitle(props.value);
        } else {
            setTitle("");
        }
    }, [props.value]);

    useEffect(() => {
        setTitle(biblioText);
    }, [biblioText]);

    const handleInputChange = (e) => {
        setTitle(e.target.value);
        if (props.onChange) {
            props.onChange(e);
        }
    };

    const handleInputBlur = () => {
        if (title === "" && props.allowEmpty === false) {
            Alert("warn", "에러", "내용을 입력해주세요.", () => {
                if (inputRef.current) {
                    setIsEditable(true);
                    inputRef.current.focus();
                }
            });
        } else {
            setIsEditable(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Escape") {
            setTitle(oldValue);
            setIsEditable(false);
        } else {
            genericEnterKeyDown(e, () => {
                inputRef.current.blur();
                e.preventDefault();
            });
        }
    };

    useEffect(() => {
        if (isEditable && isEditableRef.current === false) {
            setOldValue(title);
        }

        if (!isEditable && isEditableRef.current === true) {
            if (props.saveAct && title !== oldValue) {
                props.saveAct();
            }
        }

        isEditableRef.current = isEditable;
    }, [isEditable]);

    const handleContainerClick = () => {
        setIsEditable(true);
    };

    return (
        <Container onClick={handleContainerClick}>
            <Input
                ref={inputRef}
                name={props.name}
                isEditable={isEditable}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                readOnly={!isEditable}
                rows={1}
                placeholder={props.placeholder}
                value={title}
                padding={props.padding}
            />
        </Container>
    );
}

export default ReferenceDetailTitleInput;
