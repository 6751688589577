import React, { forwardRef, useRef } from "react";
import { Tree, UncontrolledTreeEnvironment } from "react-complex-tree";

var isTreeItemDragging = false;

const TreeEnvironmentWrapper = forwardRef((props, ref) => {
    const {
        dataProvider,
        viewState,
        treeId,
        onSelectItems,
        onDrop,
        renderItem,
        canDragAndDrop = true,
        canReorderItems = true,
        canDropOnFolder = false,
        canDropOnNonFolder = false,
        multiSelect = true,
        onExpandItem,
        onCollapseItem,
    } = props;
    const environmentRef = useRef(null);
    const treeRef = useRef(null);

    React.useImperativeHandle(ref, () => ({
        expandItem: (itemId) => {
            treeRef.current?.expandItem(itemId);
        },
        collapseItem: (itemId) => {
            treeRef.current?.collapseItem(itemId);
        },
        unselectAllItems: () => {
            treeRef.current?.selectItems([]);
        },
    }));

    const handleDrop = () => {
        isTreeItemDragging = false;

        const assignLinearIndex = (currentItems, rootItem) => {
            let index = 0;
            const linearIndices = {};

            const traverseTree = (itemId) => {
                linearIndices[itemId] = index++;
                const children = currentItems[itemId]?.children ?? [];
                children.forEach(traverseTree);
            };

            traverseTree(rootItem);
            return linearIndices;
        };

        // 전체 트리 상태 가져오기
        const currentItems = dataProvider.data.items;
        const rootItem = "root"; // 트리의 루트 아이템

        // 부모 정보를 식별하기 위한 맵 구성
        const parentMap = {};
        Object.keys(currentItems).forEach((itemId) => {
            const item = currentItems[itemId];
            const children = item.children ?? [];
            children.forEach((childId) => {
                parentMap[childId] = itemId; // 자식 아이템 ID를 키로 사용하여 부모 ID 매핑
            });
        });

        // 트리를 순회하며 모든 노드에 대한 `linearIndex`를 계산
        const allLinearIndices = assignLinearIndex(currentItems, rootItem);

        // 모든 노드에 대한 부모 및 순서 정보 수집
        const updatedNodes = Object.keys(currentItems)
            .filter((itemId) => itemId !== rootItem) // `root`를 제외
            .map((itemId) => {
                const parent = parentMap[itemId] || null; // 부모가 없으면 null
                return {
                    id: itemId,
                    parent: parent === rootItem ? null : parent,
                    order: allLinearIndices[itemId], // 0부터 시작하는 순서
                };
            });

        // 서버에 업데이트 요청
        onDrop(updatedNodes);
    };

    return (
        <UncontrolledTreeEnvironment
            key={JSON.stringify(viewState)}
            ref={environmentRef}
            dataProvider={dataProvider}
            viewState={viewState}
            getItemTitle={(item) => item.name}
            canDragAndDrop={canDragAndDrop}
            canReorderItems={canReorderItems}
            canDropOnFolder={canDropOnFolder}
            canDropOnNonFolder={canDropOnNonFolder}
            defaultInteractionMode={{
                mode: "custom",
                createInteractiveElementProps: (item, treeId, actions, renderFlags) => ({
                    onClick: (e) => {
                        actions.focusItem(); // 기본 포커스 동작 유지
                        if (multiSelect) {
                            // 다중 선택 모드
                            if (e.shiftKey) {
                                actions.selectUpTo(true); // Shift 키를 누르면 범위 선택
                            } else {
                                if (renderFlags.isSelected) {
                                    actions.unselectItem(); // 선택 해제
                                } else {
                                    actions.addToSelectedItems(); // 선택 추가
                                }
                            }
                        } else {
                            if (renderFlags.isSelected) {
                                actions.unselectItem(); // 선택 해제
                            } else {
                                actions.selectItem();
                                treeRef.current?.selectItems([item.index]);
                            }
                        }
                    },
                    onFocus: () => {
                        actions.focusItem();
                    },
                    onDragStart: (e) => {
                        isTreeItemDragging = true;
                        e.dataTransfer.dropEffect = "move";
                        actions.startDragging();
                    },
                    onDragOver: (e) => {
                        e.preventDefault();
                    },
                    onDragEnd: (e) => {
                        isTreeItemDragging = false;
                    },
                    draggable: renderFlags.canDrag && !renderFlags.isRenaming,
                }),
            }}
            onSelectItems={(selectedIds) => {
                if (!isTreeItemDragging) {
                    if (multiSelect) {
                        // 다중 선택 모드: 선택된 항목 그대로 전달
                        onSelectItems(selectedIds);
                    } else {
                        const selectedId = selectedIds.length > 0 ? selectedIds[selectedIds.length - 1] : null;
                        onSelectItems(selectedId ? [selectedId] : []);
                    }
                }
            }}
            onExpandItem={(item) => {
                onExpandItem(item);
            }}
            onCollapseItem={(item) => {
                onCollapseItem(item);
            }}
            onDrop={handleDrop}
            renderItem={renderItem}
        >
            <Tree treeId={treeId} rootItem="root" ref={treeRef} />
        </UncontrolledTreeEnvironment>
    );
});

export default TreeEnvironmentWrapper;
