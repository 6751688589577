import React, { useState } from "react";

import styled from "styled-components";

import Checkbox from "components/atoms/Checkbox";
import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";

import InputWithLabel from "components/molecules/InputWithLabel";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { apiUrl } from "utils/urls";
import { genericEnterKeyDown } from "utils/validation";

import axios from "axios";

const Container = styled.div`
    width: 100%;
`;

const Row = styled.div`
    display: flex;
    align-items: center;
    margin: 8px 0 0 0;
`;

function ReferenceAddArxivModal(props) {
    const [arXivId, setArXivId] = useState("");
    const [pdfAutoAdd, setPdfAutoAdd] = useState(false);
    const { setLoading } = useLoading();

    const handleAddButton = () => {
        setLoading(true, "참고문헌 정보를 확인 중입니다.");
        axios
            .post(apiUrl("paperMetadata"), {
                reference_id: arXivId,
                reference_source: "arxiv",
            })
            .then((res) => {
                setLoading(false);
                if (pdfAutoAdd) {
                    props.mutation.mutate({
                        body: {
                            display_name: res.data.display_name,
                            bib_json: res.data.bib_json,
                            file_url: res.data.file_url,
                        },
                    });
                } else {
                    props.mutation.mutate({
                        body: {
                            display_name: res.data.display_name,
                            bib_json: res.data.bib_json,
                        },
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                Alert("warn", "에러", "참고문헌 추가 중 오류가 발생했습니다.");
            });
    };

    return (
        <Container>
            <GeneralText size={"regular"}>참고문헌 추가를 위해 ArXiv ID를 입력해주세요.</GeneralText>
            <InputWithLabel
                width="100%"
                margin="24px 0 0 0"
                labelText="ArXiv ID"
                essenMarkDp="none"
                bgColor="var(--color-Base1)"
                infoTextDp="block"
                infoText="ArXiv"
                tooltipDp="flex"
                tooltipMessage="arXiv ID는 과학 분야의 논문을 수집하는 arXiv에서 각 논문에 부여되는 고유한 식별자 번호입니다."
                placeholder="EX) 1706.03762"
                onChange={(e) => setArXivId(e.target.value)}
                onKeyDown={(e) => {
                    genericEnterKeyDown(e, () => {
                        handleAddButton();
                    });
                }}
                value={arXivId}
            />
            <Row>
                <Checkbox margin={"0"} checked={pdfAutoAdd} onChange={(e) => setPdfAutoAdd(!pdfAutoAdd)} />
                <GeneralText size={"small"} margin={"0 0 0 8px"} color={"var(--color-Grey1)"}>
                    원문 PDF 자동 추가
                </GeneralText>
            </Row>
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"추가"}
                leftOnClick={props.exitModal}
                rightOnClick={handleAddButton}
                disabled={!arXivId}
            />
        </Container>
    );
}

export default ReferenceAddArxivModal;
