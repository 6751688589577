import React, { useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";

import { usePaymentPlanGroupUserCreate, usePaymentPlanGroupUserDelete } from "hooks/queries/usePayment";

import { emailValidation, genericEnterKeyDown } from "utils/validation";

const Container = styled.div`
    width: 100%;
`;

const HeaderContainer = styled.div`
    display: flex;
    align-items: end;
    padding-bottom: 12px;
    justify-content: flex-end;
    width: 100%;
`;

const MemberCount = styled.div`
    font-size: 12px;
    font-weight: 400;
`;

const MemberList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: 12px;
    font-weight: 400;
`;

const MemberItem = styled.li`
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding: 10px 0;
`;

const MemberEmail = styled.span`
    color: #333;
`;

const RemoveButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const AddMemberWrap = styled.div`
    display: flex;
    align-items: center;
    padding: 0 0 0 16px;
    width: 100%;
    height: 32px;
    border-radius: 4px;
    border: solid 1px var(--color-Black);
    overflow: hidden;
`;

const AddMemberInput = styled.input`
    flex: 1;
    height: 100%;
    background-color: var(--color-White);
    border: transparent;
    outline: none;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-Black);

    &::placeholder {
        color: var(--color-Grey2);
    }
`;

function PlanGroupMemberModal(props) {
    const [inviteEmail, setInviteEmail] = useState("");
    const paymentPlanGroupUserCreateMutation = usePaymentPlanGroupUserCreate();
    const paymentPlanGroupUserDeleteMutation = usePaymentPlanGroupUserDelete();

    const invite = () => {
        // email validation
        if (!inviteEmail) {
            return;
        }

        if (emailValidation(inviteEmail) === false) {
            Alert("warn", "에러", "이메일 형식이 올바르지 않습니다.");
        }
        const existUser = props.paymentPlanGroupUser.find((user) => user.email === inviteEmail);
        if (existUser) {
            Alert("warn", "에러", "이미 초대된 사용자입니다.");
            return;
        }

        // max_member validation
        if (
            props.paymentPlanGroupUser.filter((user) => user.invitation_status !== "rejected").length >=
            props.paymentPlanGroup.max_member
        ) {
            Alert("warn", "에러", "최대 인원을 초과하였습니다.");
            return;
        }

        paymentPlanGroupUserCreateMutation.mutate(
            {
                group: props.paymentPlanGroup.id,
                email: inviteEmail,
            },
            {
                onSuccess: () => {
                    setInviteEmail("");
                },
            },
        );
    };

    return (
        <Container>
            <HeaderContainer>
                <MemberCount>
                    {props.paymentPlanGroupUser.filter((user) => user.invitation_status !== "rejected").length}명 /{" "}
                    {props.paymentPlanGroup.max_member}명
                </MemberCount>
            </HeaderContainer>
            <AddMemberWrap>
                <AddMemberInput
                    placeholder="초대할 이메일 주소를 입력해주세요."
                    value={inviteEmail}
                    onChange={(e) => setInviteEmail(e.target.value)}
                    onKeyPress={(e) => {
                        genericEnterKeyDown(e, () => {
                            invite();
                        });
                    }}
                />
                <Button
                    onlyIcon
                    onClick={invite}
                    buttonSize={"32px"}
                    bgColor={"var(--color-Black)"}
                    hoverBgColor={"var(--color-ButtonHover1)"}
                    borderRadius={"0"}
                >
                    <Icon name={"plus"} size={"12"} color={"var(--color-White)"} />
                </Button>
            </AddMemberWrap>
            <MemberList>
                {props.paymentPlanGroupUser.map((user) => {
                    return (
                        <MemberItem key={user.id}>
                            <MemberEmail>
                                {user.email}
                                {user.admin === user.email && "(관리자)"}
                                {user.invitation_status === "pending" && " (수락 대기 중)"}
                                {user.invitation_status === "rejected" && " (거절)"}
                            </MemberEmail>
                            {user.admin !== user.email && (
                                <RemoveButton
                                    onClick={() => {
                                        Confirm(
                                            "warn",
                                            "알림",
                                            "상대방을 단체 플랜에서 제외하시겠습니까?",
                                            "구독 해지",
                                            () => {
                                                paymentPlanGroupUserDeleteMutation.mutate(user.id);
                                            },
                                        );
                                    }}
                                >
                                    ✕
                                </RemoveButton>
                            )}
                        </MemberItem>
                    );
                })}
            </MemberList>
        </Container>
    );
}

export default PlanGroupMemberModal;
