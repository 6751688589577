import { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import SelectBox from "components/atoms/SelectBox";
import Tooltip from "components/atoms/Tooltip";
import ToggleButton from "components/atoms/button/ToggleButton";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import ReviewChatThreadItem from "components/organisms/review/ReviewChatThreadItem";

import { genericEnterKeyDown } from "utils/validation";

const Container = styled.div`
    position: relative;
    width: 100%;
    height: calc(100% - 56px);
    min-height: calc(100vh - 56px);
    border-left: solid 1px var(--color-Outline);
    overflow-x: hidden;
    overflow-y: auto;
`;

const Header = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: ${({ fold }) => (fold ? "40px" : "120px")};
    background-color: var(--color-White);
    border-bottom: solid 1px var(--color-Outline);
    box-shadow: ${({ fold }) => (fold ? "1px 1px 8px rgba(0, 0, 0, 0.0);" : "1px 1px 8px rgba(0, 0, 0, 0.1);")};
    overflow: hidden;
    transition: all 120ms ease-in;
    z-index: 1;
`;

const HeaderInnerWrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4px;
    width: 100%;
    height: 40px;
`;

const OptionButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: var(--color-White);
    border-radius: 4px;
    border: transparent;
    outline: none;
    transform: ${({ fold }) => (fold ? "rotate(0deg)" : "rotate(180deg)")};
    transition: background-color 120ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const Blank = styled.div`
    width: 32px;
    height: 32px;
`;

const OptionBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 8px;
    padding: 0 16px;
    background-color: var(--color-White);
`;

const OptionWrap = styled.div`
    display: flex;
    align-items: center;
    width: fit-content;
    height: 32px;
`;

const TextResizeWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: fit-content;
    height: 24px;
`;

const TextResizeButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px 0 0;
    width: 24px;
    height: 24px;
    font-size: 12px;
    font-weight: 600;
    color: var(--color-White);
    background-color: ${({ selected }) => (selected ? "var(--color-Button1)" : "var(--color-ButtonHover4)")};
    border-radius: 6px;
    border: transparent;
    outline: none;
    transition: all 120ms ease-in;

    &:hover {
        background-color: ${({ selected }) => (selected ? "var(--color-ButtonHover1)" : "var(--color-ButtonHover3)")};
    }
`;

const DivideLine = styled.span`
    display: block;
    margin: 0 12px;
    width: 1px;
    height: 16px;
    background-color: var(--color-Line);
`;

const ChatContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    background-color: transparent;
    z-index: 15;
`;

const ChatBox = styled.div`
    margin: 0 16px 16px 16px;
    width: 100%;
    height: auto;
    background-color: var(--color-White);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
`;

const InputWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 4px 4px 0 4px;
    width: 100%;
    max-height: 245px;
`;

const ChatInput = styled.textarea`
    padding: 5px 0 0 4px;
    width: calc(100% - 40px);
    min-height: 32px;
    height: 32px;
    max-height: 240px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    border: transparent;
    outline: none;
    resize: none;
`;

const SendButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    max-height: 32px;
    background-color: var(--color-Button1);
    border-radius: 4px;
    border: transparent;
    transition: all 120ms ease-in;
    outline: none;

    &:hover {
        background-color: var(--color-ButtonHover1);
    }

    &:disabled {
        background-color: var(--color-DisabledButton);
    }
`;

const ChatOptionWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0 0 0;
    padding: 4px;
    width: 100%;
    background-color: var(--color-BaseBlue);
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
`;

const ChatOptionBtnWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 68px;
    height: 32px;
`;

const ChatOptionButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    background-color: ${({ active }) => (active ? "var(--color-ButtonHover5)" : "transparent")};
    border-radius: 4px;
    border: transparent;
    outline: none;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover5);
    }
`;

const ChatSelectWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: calc(100% - 80px);
`;

const ChatSelect = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    max-width: 200px;
`;

const RecomQueryWrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 4px 0;
    width: 100%;
    background-color: var(--color-White);
    border-bottom: solid 1px var(--color-Outline);
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
`;

const RecomHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 4px;
    width: 100%;
    border-bottom: solid 1px var(--color-Outline);
`;

const RecomExitButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 4px 0 0;
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: transparent;
    outline: none;
`;

const RecomListWrap = styled.div`
    margin: 8px 0 8px 0;
    padding: 0 8px;
    width: 100%;
`;

const RecomQueryList = styled.ul`
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
`;

const RecomQueryItem = styled.li`
    margin: 0;
    padding: 0;
    width: 100%;
    list-style: none;
    cursor: pointer;

    &:first-child {
        > div {
            margin: 0 0 4px 0;
        }
    }

    &:last-child {
        margin: 4px 0 0 0;

        > div {
            margin: 4px 0 0 0;
        }
    }
`;

const ItemInner = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 4px 0 4px 0;
    padding: 4px;
    border-radius: 2px;
    transition: all 120ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const ItemIcon = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 14px;
    height: 20px;
`;

const ThreadListContainer = styled.div`
    margin: 40px 0 0 0;
    padding: 0 0 100px 0;
    width: 100%;
    height: calc(100% - 40px);
    background-color: var(--color-White);
    overflow: auto;

    &::-webkit-scrollbar {
        display: initial;
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-SubBlack);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-Grey3);
    }
`;

const ThreadDetailContainer = styled.div`
    position: absolute;
    bottom: 0;
    right: ${({ active }) => (active ? "0" : "-560px")};
    padding: 0 0 100px 0;
    width: 100%;
    height: calc(100% - 40px);
    background-color: var(--color-White);
    transition: 400ms ease-in-out;
    overflow-x: hidden;
    overflow-y: auto;
    z-index: 10;

    &::-webkit-scrollbar {
        display: initial;
        width: 6px;
        height: 6px;
    }

    &::-webkit-scrollbar-thumb {
        background-color: var(--color-SubBlack);
    }

    &::-webkit-scrollbar-track {
        background-color: var(--color-Grey3);
    }
`;

function ReviewChat(props) {
    const [showOptionView, setShowOptionView] = useState(false);
    const [showDeletedMessage, setShowDeletedMessage] = useState(false);
    const [isSelectedSize, setIsSelectedSize] = useState("S");
    const [showRecommendedQuestionView, setShowRecommendedQuestionView] = useState(false);
    const [showThreadDetailView, setShowThreadDetailView] = useState(false);
    const [inputQuestion, setInputQuestion] = useState("");

    const textareaRef = useRef();
    const sendButtonRef = useRef();

    const handleTextSizeChange = (size) => {
        setIsSelectedSize(size === isSelectedSize ? null : size);
    };

    const handleQuestion = (q) => {
        setInputQuestion("");
        setShowRecommendedQuestionView(false);

        props.createChat(q);
    };

    const enterThreadDetailView = (thread) => {
        props.setSelectedThread(thread);

        props.setShowCaptureView(false);
        setShowOptionView(false);
        setShowRecommendedQuestionView(false);

        setShowThreadDetailView(true);
    };
    const leaveThreadDetailView = () => {
        props.setSelectedThread(null);

        setShowThreadDetailView(false);
    };

    // 질문 입력창 크기 조절
    useEffect(() => {
        const textarea = textareaRef.current;
        const sendButton = sendButtonRef.current;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
        sendButton.style.height = `${textarea.scrollHeight}px`;
    }, [inputQuestion]);

    // 스크롤 관련 영역
    const listScrollRef = useRef();
    const detailScrollRef = useRef();
    useEffect(() => {
        if (props.autoScrollToEnd) {
            if (listScrollRef.current) {
                listScrollRef.current.scrollTop = listScrollRef.current.scrollHeight;
            }
            if (detailScrollRef.current) {
                detailScrollRef.current.scrollTop = detailScrollRef.current.scrollHeight;
            }
        }
    }, [props.threads]);

    return (
        <Container>
            {/* 사이드바 헤더 */}
            <Header fold={!showOptionView}>
                <HeaderInnerWrap>
                    {showThreadDetailView ? (
                        // 스레드 상세에서는 나가기 버튼
                        <OptionButton onClick={leaveThreadDetailView}>
                            <Icon name={"arrowRight"} size={"12"} color={"var(--color-SubBlack)"} />
                        </OptionButton>
                    ) : (
                        // 스레드 목록에서는 옵션뷰 보기 버튼
                        <OptionButton
                            onClick={(e) => {
                                setShowOptionView(!showOptionView);
                            }}
                            fold={!showOptionView}
                        >
                            <Icon name={"arrowDown"} size={"12"} color={"var(--color-SubBlack)"} />
                        </OptionButton>
                    )}
                    <TitleText size={"subRegular"}>{showThreadDetailView ? "대화 상세" : "대화 목록"}</TitleText>
                    <Blank />
                </HeaderInnerWrap>
                <OptionBox>
                    <OptionWrap>
                        <TitleText
                            size={"subSmall"}
                            margin={"0 8px 0 0"}
                            fontWeight={"regular"}
                            color={"var(--color-Grey1)"}
                        >
                            삭제된 대화 보기
                        </TitleText>
                        <ToggleButton
                            onChange={(e) => {
                                setShowDeletedMessage(e.target.checked);
                            }}
                            checked={showDeletedMessage}
                        />
                    </OptionWrap>
                    <DivideLine />
                    <OptionWrap>
                        <TitleText
                            size={"subSmall"}
                            margin={"0 8px 0 0"}
                            fontWeight={"regular"}
                            color={"var(--color-Grey1)"}
                        >
                            글자 크기
                        </TitleText>
                        <TextResizeWrap>
                            <TextResizeButton
                                selected={isSelectedSize === "S"}
                                onClick={() => handleTextSizeChange("S")}
                            >
                                S
                            </TextResizeButton>
                            <TextResizeButton
                                selected={isSelectedSize === "M"}
                                onClick={() => handleTextSizeChange("M")}
                            >
                                M
                            </TextResizeButton>
                            <TextResizeButton
                                selected={isSelectedSize === "L"}
                                onClick={() => handleTextSizeChange("L")}
                            >
                                L
                            </TextResizeButton>
                        </TextResizeWrap>
                    </OptionWrap>
                </OptionBox>
            </Header>

            {/* 사이드바 입력 영역 */}
            <ChatContainer>
                <ChatBox>
                    {showRecommendedQuestionView && (
                        <RecomQueryWrap>
                            <RecomHeader>
                                <TitleText size={"subSmall"} fontWeight={"regular"} color={"var(--color-SubBlack)"}>
                                    추천질문
                                </TitleText>
                                <RecomExitButton
                                    onClick={(e) => {
                                        setShowRecommendedQuestionView(false);
                                    }}
                                >
                                    <Icon name={"x"} size={"8"} color={"var(--color-SubBlack)"} />
                                </RecomExitButton>
                            </RecomHeader>
                            <RecomListWrap>
                                <RecomQueryList>
                                    {props.questions &&
                                        props.questions.length > 0 &&
                                        props.questions?.map((question, index) => (
                                            <RecomQueryItem
                                                key={index}
                                                onClick={(e) => {
                                                    handleQuestion(question);
                                                }}
                                            >
                                                <ItemInner>
                                                    <ItemIcon>
                                                        <Icon
                                                            name={"question"}
                                                            size={"12"}
                                                            color={"var(--color-Key)"}
                                                        />
                                                    </ItemIcon>
                                                    <GeneralText size={"small"} margin={"0 0 0 8px"}>
                                                        {question}
                                                    </GeneralText>
                                                </ItemInner>
                                            </RecomQueryItem>
                                        ))}
                                </RecomQueryList>
                            </RecomListWrap>
                        </RecomQueryWrap>
                    )}
                    <InputWrap>
                        <ChatInput
                            autoFocus
                            ref={textareaRef}
                            rows={1}
                            placeholder="여기에 질문을 입력해주세요."
                            value={inputQuestion}
                            onChange={(e) => {
                                setInputQuestion(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                genericEnterKeyDown(e, () => {
                                    if (e.shiftKey) {
                                        setInputQuestion((prev) => prev + "\n");
                                    } else {
                                        handleQuestion(inputQuestion);
                                    }
                                });
                            }}
                        />
                        <SendButton
                            ref={sendButtonRef}
                            disabled={inputQuestion.trim() === ""}
                            onClick={(e) => {
                                handleQuestion(inputQuestion);
                            }}
                        >
                            <Icon name={"enter"} color={"var(--color-White)"} />
                        </SendButton>
                    </InputWrap>
                    <ChatOptionWrap>
                        <ChatOptionBtnWrap>
                            <Tooltip message={"영역선택"} position={"top"}>
                                <ChatOptionButton
                                    onClick={(e) => {
                                        props.setShowCaptureView(!props.showCaptureView);
                                    }}
                                    active={props.showCaptureView}
                                >
                                    <Icon name={"capture"} size={"16"} color={"var(--color-SubBlack)"} />
                                </ChatOptionButton>
                            </Tooltip>
                            <Tooltip message={"추천질문"} position={"top"}>
                                <ChatOptionButton
                                    onClick={(e) => {
                                        setShowRecommendedQuestionView(!showRecommendedQuestionView);
                                    }}
                                    active={showRecommendedQuestionView}
                                >
                                    <Icon name={"question"} size={"15"} color={"var(--color-SubBlack)"} />
                                </ChatOptionButton>
                            </Tooltip>
                        </ChatOptionBtnWrap>
                        <ChatSelectWrap>
                            <ChatSelect style={{ margin: "0 8px 0 0" }}>
                                <TitleText margin={"0 4px 0 0"} color={"var(--color-Grey1)"}>
                                    응답
                                    <br />
                                    언어
                                </TitleText>
                                <SelectBox
                                    width="calc(100% - 28px)"
                                    maxWidth="120px"
                                    optionWidth="100%"
                                    optionMinWidth="120px"
                                    topBottom="bottom"
                                    reverse="180deg"
                                    value={props.language}
                                    items={props.languageOptions}
                                    onChange={(e) => props.setLanguage(e)}
                                />
                            </ChatSelect>
                            <ChatSelect>
                                <TitleText margin={"0 4px 0 0"} color={"var(--color-Grey1)"}>
                                    응답
                                    <br />
                                    출처
                                </TitleText>
                                <SelectBox
                                    width="calc(100% - 28px)"
                                    maxWidth="180px"
                                    optionWidth="100%"
                                    optionMinWidth="180px"
                                    topBottom="bottom"
                                    reverse="180deg"
                                    value="1"
                                    items={[
                                        { id: "1", name: "현재 참고문헌" },
                                        // { id: "2", name: "인용된 리서치의 참고문헌" },
                                        // { id: "3", name: "모든 참고문헌" },
                                    ]}
                                    onChange={(e) => {
                                        console.log(e);
                                    }}
                                />
                            </ChatSelect>
                        </ChatSelectWrap>
                    </ChatOptionWrap>
                </ChatBox>
            </ChatContainer>

            {/* 쓰레드 목록 */}
            <ThreadListContainer ref={listScrollRef}>
                {props.threads.map((thread, index) => (
                    <ReviewChatThreadItem
                        key={index}
                        isThread={true}
                        thread={thread}
                        message={
                            showDeletedMessage
                                ? thread.messages[0]
                                : thread.messages.find((x) => x.is_deleted === false)
                        }
                        showDeletedMessage={showDeletedMessage}
                        enterThreadDetailView={enterThreadDetailView}
                        jumpToHighlightArea={props.jumpToHighlightArea}
                        updateChatOrMemo={props.updateChatOrMemo}
                        deleteChatOrMemo={props.deleteChatOrMemo}
                        busyMessage={props.busyMessage}
                        stopChat={props.stopChat}
                    />
                ))}
            </ThreadListContainer>

            {/* 스레드 상세 영역 */}
            <ThreadDetailContainer ref={detailScrollRef} active={showThreadDetailView}>
                {props.selectedThread?.messages.map((message, index) => (
                    <ReviewChatThreadItem
                        key={index}
                        isThread={false}
                        thread={props.selectedThread}
                        message={message}
                        showDeletedMessage={showDeletedMessage}
                        enterThreadDetailView={enterThreadDetailView}
                        jumpToHighlightArea={props.jumpToHighlightArea}
                        updateChatOrMemo={props.updateChatOrMemo}
                        deleteChatOrMemo={props.deleteChatOrMemo}
                        busyMessage={props.busyMessage}
                        stopChat={props.stopChat}
                    />
                ))}
            </ThreadDetailContainer>
        </Container>
    );
}

export default ReviewChat;
