import React, { useEffect, useState } from "react";
import { StaticTreeDataProvider } from "react-complex-tree";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Confirm from "components/atoms/alert/Confirm";
import StyledText from "components/atoms/text/StyledText";
import useToast from "components/atoms/toast/useToast";

import ContextMenu from "components/molecules/ContextMenu";
import TreeEnvironmentWrapper from "components/molecules/TreeEnvironmentWrapper";
import AbstractModal from "components/page/modal/AbstractModal";
import TagEditModal from "components/page/modal/TagEditModal";

import {
    useTagBulkDelete,
    useTagChangeOrder,
    useTagCreate,
    useTagUpdate,
    useTags,
} from "hooks/queries/useBibliographies";

import Constants from "utils/constants";

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const BodyContainer = styled.div`
    overflow-y: auto;
`;

const TreeItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`;

const TreeItemMain = styled.div`
    display: flex;
    align-items: center;
    margin: 1px 0;
    padding: 0 2px;
    width: 100%;
    border-radius: 4px;
    background-color: ${(props) =>
        props.isDraggingOver ? "var(--color-Button3)" : props.isSelected ? "var(--color-Button4)" : "transparent"};

    &:hover {
        background-color: var(--color-Button4);
    }
`;

const IconBox = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 10px;
    height: 24px;
`;

const TreeItemKnob = styled.div`
    width: 20px;
    height: 20px;
    visibility: hidden;

    ${TreeItemMain}:hover & {
        visibility: visible;
    }
    &:hover {
        cursor: pointer;
    }
`;

const TreeItemChildren = styled.div`
    width: 100%;
`;

const IconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const LoadingWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
`;

const TreeItemTitle = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;

    &:hover {
        cursor: ${(props) => (props.treeId === Constants.TAG_TREE_ID_SIDEMENU ? "pointer" : "default")};
    }
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
`;

const TextWrapper = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ItemText = styled(StyledText)`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.4rem;
    font-weight: ${(props) => (props.isSelected ? "500" : "400")};
    color: ${(props) => (props.isSelected ? "var(--color-Black)" : "var(--color-SubBlack)")};
`;

const ItemCountText = styled(StyledText)`
    margin-left: 4px;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-Grey1);
`;

function ReferenceFilterTagView(props) {
    // props:
    // viewState
    // onSelectItems
    // treeId
    const [tags, setTags] = useState({
        root: {
            index: "root",
            name: "root",
        },
    });

    const [showTooltip, setShowTooltip] = useState(false);

    const tagQuery = useTags();
    const tagUpdate = useTagUpdate();
    const tagCreate = useTagCreate();
    const tagDelete = useTagBulkDelete();
    const tagChangeOrder = useTagChangeOrder();
    const [viewState, setViewState] = useState({
        [props.treeId]: {
            selectedItems: [],
        },
    });
    const { setToast } = useToast();

    useEffect(() => {
        const selectedItems = props.searchParams?.tags ? props.searchParams?.tags.split(",") : [];

        setViewState((prevState) => ({
            ...prevState,
            [props.treeId]: {
                ...prevState[props.treeId],
                selectedItems: selectedItems,
            },
        }));
    }, [props.searchParams]);

    useEffect(() => {
        if (tagQuery.data) {
            const items = {};

            const sortedTags = [...tagQuery.data].sort((a, b) => a.order - b.order);

            // 아이템을 `index`를 키로 하여 맵에 저장
            tagQuery.data.forEach((tag) => {
                items[tag.id] = {
                    index: String(tag.id),
                    name: tag.name,
                    order: tag.order,
                    parent: "root",
                    isFolder: false,
                    tagged_count: tag.tagged_count,
                    children: [],
                };
            });

            items["root"] = {
                index: "root",
                name: "root",
                children: sortedTags.map((tag) => String(tag.id)),
            };

            setTags(items);
        }
    }, [tagQuery.data]);

    useEffect(() => {
        const allNodeIds = Object.keys(tags);
        dataProvider.onDidChangeTreeDataEmitter.emit(allNodeIds);
    }, [tags]);

    const dataProvider = new StaticTreeDataProvider(tags, (item, name) => ({
        ...item,
        name,
    }));

    const removeItem = (itemIds) => {
        if (itemIds.length === 0) return;
        Confirm("warn", "태그 삭제", "태그를 삭제하시겠습니까? 태그만 삭제되며, 파일은 유지됩니다.", "삭제", () => {
            tagDelete.mutate(
                {
                    ids: Array.from(itemIds),
                },
                {
                    onSuccess: (data, variables, context) => {
                        setToast("태그가 삭제되었습니다.", "info");
                        props.onSelectItems(
                            viewState[props.treeId].selectedItems.filter((item) => !itemIds.includes(item)),
                        );
                    },
                    onError: (error) => {
                        setToast("태그 삭제에 실패했습니다. 잠시후 시도해주세요.", "error");
                    },
                },
            );
        });
    };

    const [showContextMenu, setShowContextMenu] = useState(false);
    const isFilterEmpty = !props.searchParams?.folder && !props.searchParams?.tags;

    return (
        <Container>
            <BodyContainer>
                {tagQuery.isLoading ? (
                    <LoadingWrap>
                        <Icon name={"load"} size={"40px"} />
                    </LoadingWrap>
                ) : (
                    <>
                        <TreeItemContainer
                            depth={0}
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                props.onSelectItems([]);
                            }}
                        >
                            <TreeItemMain
                                isSelected={viewState?.[props.treeId]?.selectedItems.length === 0 && isFilterEmpty}
                            >
                                <TreeItemTitle treeId={props.treeId}>
                                    <IconBox>
                                        {viewState?.[props.treeId]?.selectedItems.length === 0 && isFilterEmpty ? (
                                            <Icon name={"check"} size={"10px"} color={"var(--color-DarkKey)"} />
                                        ) : (
                                            <Icon name={"tag"} size={"10px"} color={"#9FA8AB"} />
                                        )}
                                    </IconBox>
                                    <ItemText
                                        size={"regular"}
                                        isSelected={
                                            viewState?.[props.treeId]?.selectedItems.length === 0 && isFilterEmpty
                                        }
                                    >
                                        All References
                                    </ItemText>
                                </TreeItemTitle>
                            </TreeItemMain>
                        </TreeItemContainer>
                        <TreeEnvironmentWrapper
                            treeId={props.treeId}
                            dataProvider={dataProvider}
                            viewState={viewState}
                            onSelectItems={(selectedItems) => {
                                props.onSelectItems(selectedItems);
                            }}
                            onDrop={(updatedNodes) => {
                                tagChangeOrder.mutate(updatedNodes, {
                                    onSuccess: () => {
                                        setToast("success", "태그 순서 변경", "태그 순서가 변경되었습니다.");
                                    },
                                });
                            }}
                            renderItem={({ item, info, title, arrow, depth, context, children }) => {
                                return (
                                    <TreeItemContainer
                                        {...context.itemContainerWithChildrenProps}
                                        onMouseLeave={() => setShowContextMenu(false)}
                                    >
                                        <TreeItemMain
                                            {...context.itemContainerWithoutChildrenProps}
                                            {...context.interactiveElementProps}
                                            isDraggingOver={context.isDraggingOver}
                                            isSelected={context.isSelected}
                                        >
                                            <TreeItemTitle treeId={props.treeId}>
                                                <IconBox>
                                                    {context.isSelected ? (
                                                        <Icon
                                                            name={"check"}
                                                            size={"10px"}
                                                            color={"var(--color-DarkKey)"}
                                                        />
                                                    ) : (
                                                        <Icon name={"tag"} size={"10px"} color={"#9FA8AB"} />
                                                    )}
                                                </IconBox>
                                                <TitleWrapper>
                                                    <TextWrapper>
                                                        <ItemText isSelected={context.isSelected}>{title}</ItemText>
                                                    </TextWrapper>
                                                    <ItemCountText>&nbsp;{item.tagged_count}</ItemCountText>
                                                </TitleWrapper>
                                            </TreeItemTitle>
                                            <TreeItemKnob
                                                className={`tree-knob-${item.index}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setShowContextMenu(
                                                        item.index === showContextMenu ? false : item.index,
                                                    );
                                                }}
                                            >
                                                {showTooltip ? (
                                                    <Tooltip message={"더보기"}>
                                                        <IconWrap
                                                            onMouseEnter={() => setShowTooltip(true)}
                                                            onMouseLeave={() => setShowTooltip(false)}
                                                        >
                                                            <Icon name={"submenu"} />
                                                        </IconWrap>
                                                    </Tooltip>
                                                ) : (
                                                    <IconWrap
                                                        onMouseEnter={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                    >
                                                        <Icon name={"submenu"} />
                                                    </IconWrap>
                                                )}
                                                {showContextMenu === item.index && (
                                                    <ContextMenu
                                                        show={true}
                                                        translate={"-92px, 0px"}
                                                        minWidth={"70px"}
                                                        items={[
                                                            {
                                                                name: "Rename",
                                                                action: () => {
                                                                    props.setShowTagAddModal(item);
                                                                },
                                                            },
                                                            {
                                                                name: "Delete",
                                                                action: () => {
                                                                    removeItem([item.index]);
                                                                },
                                                            },
                                                        ]}
                                                        onClose={() => setShowContextMenu(false)}
                                                        triggerClassName={`tree-knob-${item.index}`}
                                                    />
                                                )}
                                            </TreeItemKnob>
                                        </TreeItemMain>
                                        <TreeItemChildren>{children}</TreeItemChildren>
                                    </TreeItemContainer>
                                );
                            }}
                        />
                    </>
                )}
            </BodyContainer>
            {props.showTagAddModal && (
                <AbstractModal
                    modalTitle={props.showTagAddModal === true ? "Create Tag" : "Rename"}
                    width={480}
                    exitModal={(e) => props.setShowTagAddModal(false)}
                >
                    <TagEditModal
                        exitModal={(e) => props.setShowTagAddModal(false)}
                        item={props.showTagAddModal}
                        mutation={props.showTagAddModal === true ? tagCreate : tagUpdate}
                    />
                </AbstractModal>
            )}
        </Container>
    );
}

export default ReferenceFilterTagView;
