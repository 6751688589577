import React from "react";
import { confirmAlert } from "react-confirm-alert";

import AbstractAlert from "components/atoms/alert/AbstractAlert";

const Confirm = (type, title, message, buttonName, onConfirm, onCloseAction) => {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <AbstractAlert
                    onClose={() => {
                        onClose();
                        if (onCloseAction) {
                            onCloseAction();
                        }
                    }}
                    type={type}
                    title={title}
                    onConfirm={onConfirm}
                    message={message}
                    buttonName={buttonName}
                />
            );
        },
    });
};

export default Confirm;
