import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";
import useToast from "components/atoms/toast/useToast";

import FormInput from "components/molecules/FormInput";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { genericEnterKeyDown } from "utils/validation";

const Container = styled.div`
    width: 100%;
`;

function FolderEditModal(props) {
    const { mutation, exitModal, context } = props;

    const { setLoading } = useLoading();
    const { setToast } = useToast();

    const inputRef = useRef(null);

    const [folderName, setFolderName] = useState(context?.item?.name || "");

    const handleApply = () => {
        mutation.mutate(
            {
                name: folderName,
                ...(context.type === "create" && { parent: context.parentIndex || undefined }),
                ...(context.type === "update" && { id: context.item.index }),
            },
            {
                onSuccess: (data) => {
                    setLoading(false);
                    if (context.type === "create") {
                        if (context.parentIndex) {
                            props.expandItem(context.parentIndex);
                        }
                        props.onSelectItems([data.id]);
                        setToast("폴더가 생성되었습니다.", "info");
                    } else {
                        setToast("폴더가 수정되었습니다.", "info");
                    }
                    exitModal();
                },
                onError: (error, variables) => {
                    setLoading(false);
                    Alert("warn", "에러", error.response.data.name);
                },
            },
        );
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <Container>
            <FormInput
                labelDp="none"
                placeholder="Folder Name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                onKeyDown={(e) => {
                    genericEnterKeyDown(e, handleApply);
                }}
                ref={inputRef}
            />
            <AbstractModalFooter
                leftBtnText={"Cancle"}
                rightBtnText={context?.item?.index ? "Rename" : "Create"}
                leftOnClick={props.exitModal}
                rightOnClick={() => {
                    handleApply();
                }}
            />
        </Container>
    );
}

export default FolderEditModal;
