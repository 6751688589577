import React, { forwardRef, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

const Container = styled.div`
    margin: ${(props) => props.margin};
    width: ${(props) => props.width || "100%"};
`;

const LabelRow = styled.div`
    display: ${(props) => props.labelDp || "flex"};
    justify-content: space-between;
    align-items: flex-end;
`;

const InputWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    height: ${(props) => props.height || "40"}px;
    background-color: ${(props) => (props.disabled ? "var(--color-DisabledInput)" : "var(--color-White)")};
    border-radius: 4px;
    border solid 1px var(--color-InputLine);
    transition: all 80ms ease-in;

    &:focus-within {
        border: solid 1px var(--color-Key);
    }
`;

const Input = styled.input`
    padding: 0;
    width: 100%;
    height: 24px;
    vertical-align: center;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-SubBlack);
    background-color: transparent;
    border-radius: 4px;
    border: 0;
    transition: all 120ms ease-in;

    &:focus {
        outline: none;
    }

    &::placeholder {
        color: var(--color-Grey2);
    }

    &:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px var(--color-White) inset;
        box-shadow: 0 0 0 1000px var(--color-White) inset;
    }

    &:-webkit-autofill:active {
        -webkit-text-fill-color: var(--color-Black);
        text-fill-color: var(--color-Black);
    }
`;

const IconWrap = styled.div`
    position: relative;
    left: 7px;
    display: ${(props) => props.iconDp || "none"};
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
`;

const PasswordToggleIconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
`;

const TimerViewWrap = styled.div`
    display: ${(props) => props.timerDp || "none"};
    margin: 0;
    width: 28px;
`;

const ErrorMessageWrap = styled.div`
    position: absolute;
    top: ${(props) => props.top || "44"}px;
    left: 0;
`;

const FormInput = forwardRef(
    (
        {
            margin,
            width,
            labelDp,
            label,
            subMessage,
            subMessageDP,
            height,
            placeholder,
            type,
            maxLength,
            value,
            onChange,
            onKeyDown,
            disabled,
            iconDp,
            timerDp,
            children,
            errorMessage,
            onBlur,
            onFocus,
            name,
        },
        ref,
    ) => {
        const [showPassword, setShowPassword] = useState(false);

        const togglePasswordVisibility = () => {
            setShowPassword((prevState) => !prevState);
        };

        return (
            <Container margin={margin} width={width}>
                <LabelRow labelDp={labelDp}>
                    <TitleText size={"subSmall"} margin={"0 0 4px 0"} color={"var(--color-SubBlack)"}>
                        {label}
                    </TitleText>
                    <GeneralText size={"size"} color={"var(--color-Grey1"} display={subMessageDP || "none"}>
                        {subMessage}
                    </GeneralText>
                </LabelRow>
                <InputWrapper height={height} disabled={disabled}>
                    <Input
                        ref={ref}
                        placeholder={placeholder}
                        type={showPassword ? "text" : type}
                        maxLength={maxLength}
                        value={value || ""}
                        name={name}
                        onChange={onChange}
                        onKeyDown={onKeyDown}
                        disabled={disabled}
                        onBlur={onBlur}
                        onFocus={onFocus}
                        autoComplete={type === "password" ? "off" : "on"}
                    />
                    <IconWrap iconDp={iconDp}>
                        <Button
                            onlyIcon
                            onClick={togglePasswordVisibility}
                            bgColor={"transparent"}
                            hoverBgColor={"var(--color-ButtonHover4)"}
                        >
                            <PasswordToggleIconWrap>
                                <Icon name={showPassword ? "pwShow" : "pwHide"} size={"16px"} />
                            </PasswordToggleIconWrap>
                        </Button>
                    </IconWrap>
                    <TimerViewWrap timerDp={timerDp}>
                        <GeneralText size={"small"} color={"var(--color-Key)"}>
                            {children}
                        </GeneralText>
                    </TimerViewWrap>
                    <ErrorMessageWrap top={height + 4}>
                        <GeneralText size={"small"} color={"var(--color-Warn)"}>
                            {errorMessage}
                        </GeneralText>
                    </ErrorMessageWrap>
                </InputWrapper>
            </Container>
        );
    },
);

export default FormInput;
