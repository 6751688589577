import React, { useEffect, useRef, useState } from "react";
import { StaticTreeDataProvider } from "react-complex-tree";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Tooltip from "components/atoms/Tooltip";
import Confirm from "components/atoms/alert/Confirm";
import StyledText from "components/atoms/text/StyledText";
import useToast from "components/atoms/toast/useToast";

import ContextMenu from "components/molecules/ContextMenu";
import TreeEnvironmentWrapper from "components/molecules/TreeEnvironmentWrapper";
import AbstractModal from "components/page/modal/AbstractModal";
import FolderEditModal from "components/page/modal/FolderEditModal";

import {
    useFolderChangeOrder,
    useFolderCreate,
    useFolderDelete,
    useFolderMerge,
    useFolderUpdate,
    useFolders,
} from "hooks/queries/useBibliographies";

import Constants from "utils/constants";

const RefTagWrap = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const TreeItemContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-left: ${(props) => (props.depth < 1 ? "0px" : "24px")};
`;

const TreeItemMain = styled.div`
    display: flex;
    align-items: center;
    margin: 1px 0;
    padding: 0 2px;
    width: 100%;
    border-radius: 4px;
    background-color: ${(props) =>
        props.isDraggingOver ? "var(--color-Button3)" : props.isSelected ? "var(--color-Button4)" : "transparent"};
    &:hover {
        background-color: var(--color-Button4);
    }
`;

const TreeItemTitle = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
    flex: 1;
    cursor: pointer;
`;

const TitleWrapper = styled.div`
    display: flex;
    align-items: center;
    overflow: hidden;
`;

const TextWrapper = styled.div`
    flex: 1;
    overflow: hidden;
`;

const ItemText = styled(StyledText)`
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 1.4rem;
    font-weight: ${(props) => (props.isSelected ? "500" : "400")};
    color: ${(props) => (props.isSelected ? "var(--color-Black)" : "var(--color-SubBlack)")};
`;

const ItemCountText = styled(StyledText)`
    margin-left: 4px;
    white-space: nowrap;
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--color-Grey1);
`;

const TreeItemKnob = styled.div`
    width: 20px;
    height: 20px;
    visibility: hidden;
    ${TreeItemMain}:hover & {
        visibility: visible;
    }
    &:hover {
        cursor: pointer;
    }
`;

const TreeItemArrow = styled.div`
    width: 20px;
    height: 20px;

    &:hover {
        cursor: pointer;
    }
`;

const BlankBox = styled.div`
    width: 20px;
    height: 20px;
`;

const IconBox = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 10px;
    height: 24px;
`;

const TreeItemChildren = styled.div`
    width: 100%;
`;

const IconWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: 2px;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const RefTagBody = styled.div`
    overflow-y: auto;
`;

const LoadingWrap = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 30px;
`;

function ReferenceFilterFolderView(props) {
    // props:
    // viewState
    // onSelectItems
    // treeId
    const [folders, setFolders] = useState({
        root: {
            index: "root",
            name: "root",
        },
    });
    const [viewState, setViewState] = useState({
        [props.treeId]: {
            selectedItems: [],
            expandedItems: sessionStorage.getItem(`${props.treeId}-expand-items`)
                ? JSON.parse(sessionStorage.getItem(`${props.treeId}-expand-items`))
                : [],
        },
    });

    const folderQuery = useFolders();
    const folderUpdate = useFolderUpdate();
    const folderCreate = useFolderCreate();
    const folderDelete = useFolderDelete();
    const folderChangeOrder = useFolderChangeOrder();
    const folderMerge = useFolderMerge();
    const { setToast } = useToast();

    const tree = useRef(null);

    // 각 부모의 자식 노드를 재귀적으로 정렬하는 함수
    const buildTreeWithOrdering = (items, parentId) => {
        const children = Object.values(items)
            .filter((item) => item.parent === parentId)
            .sort((a, b) => a.order - b.order);

        // 각 자식 노드에 대해 재귀적으로 정렬

        children.forEach((child) => {
            child.children = buildTreeWithOrdering(items, child.index).map((childItem) => childItem.index);
        });
        return children;
    };

    useEffect(() => {
        const selectedItems = props.searchParams?.folder ? [props.searchParams.folder] : [];
        setViewState((prevState) => ({
            ...prevState,
            [props.treeId]: {
                ...prevState[props.treeId],
                selectedItems: selectedItems,
            },
        }));
    }, [props.searchParams]);

    const onCollapseItem = (item) => {
        const expandedItems = viewState[props.treeId].expandedItems.filter((id) => id !== item.index);
        setViewState((prevState) => ({
            ...prevState,
            [props.treeId]: {
                ...prevState[props.treeId],
                expandedItems: expandedItems,
            },
        }));
        sessionStorage.setItem(`${props.treeId}-expand-items`, JSON.stringify(expandedItems));
    };

    const onExpandItem = (item) => {
        const expandedItems = [...viewState[props.treeId].expandedItems, item.index];
        setViewState((prevState) => ({
            ...prevState,
            [props.treeId]: {
                ...prevState[props.treeId],
                expandedItems: expandedItems,
            },
        }));
        sessionStorage.setItem(`${props.treeId}-expand-items`, JSON.stringify(expandedItems));
    };

    useEffect(() => {
        if (folderQuery.data) {
            const items = {};
            // 아이템을 `index`를 키로 하여 맵에 저장
            folderQuery.data.forEach((folder) => {
                items[folder.id] = {
                    index: folder.id,
                    name: folder.name,
                    children: folder.children,
                    order: folder.order,
                    parent: folder.parent,
                    isFolder: folder.children.length > 0,
                    reference_count: folder.reference_count,
                };
            });

            // 재귀적으로 트리를 정렬
            const rootChildren = buildTreeWithOrdering(items, null).map((item) => item.index);

            // `root` 노드에 최상위 레벨의 자식 노드를 추가
            items["root"] = {
                index: "root",
                name: "root",
                children: rootChildren,
            };

            setFolders(items);
        }
    }, [folderQuery.data]);

    const dataProvider = new StaticTreeDataProvider(folders, (item, name) => ({
        ...item,
        name,
    }));

    useEffect(() => {
        const allNodeIds = Object.keys(folders);
        dataProvider.onDidChangeTreeDataEmitter.emit(allNodeIds);
    }, [folders]);

    dataProvider.onChangeItemChildren = function (itemId, newChildren) {
        const item = this.data.items[itemId];
        item.children = newChildren;
        item.isFolder = newChildren.length > 0;
        this.onDidChangeTreeDataEmitter.emit([itemId]);
    };

    const removeItem = (itemIds = undefined) => {
        if (!itemIds) {
            itemIds = props.viewState[props.treeId].selectedItems;
        }

        const deleteItems = new Set(); // 중복을 제거하기 위해 Set 사용

        const addToDeleteItems = (itemId) => {
            if (!deleteItems.has(itemId)) {
                deleteItems.add(itemId);
                const children = folders[itemId].children || [];
                children.forEach(addToDeleteItems);
            }
        };

        itemIds.forEach(addToDeleteItems);

        Confirm("warn", "폴더 삭제", "폴더를 삭제하시겠습니까? 하위 폴더들도 함께 삭제됩니다.", "삭제", () => {
            folderDelete.mutate(
                {
                    ids: Array.from(deleteItems),
                },
                {
                    onSuccess: (data, variables, context) => {
                        setToast("폴더가 삭제되었습니다.", "info");
                        const selectedItem = viewState[props.treeId].selectedItems[0];
                        if (deleteItems.has(selectedItem)) {
                            props.onSelectItems([]);
                        }
                    },
                    onError: (error) => {
                        setToast("폴더 삭제에 실패했습니다. 잠시후 시도해주세요.", "error");
                    },
                },
            );
        });
    };

    const isFilterEmpty = !props.searchParams?.folder && !props.searchParams?.tags;

    const [showContextMenu, setShowContextMenu] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);
    return (
        <>
            {
                // 로딩 이미지
                folderQuery.isLoading ? (
                    <LoadingWrap>
                        <Icon name={"load"} size={"40px"} />
                    </LoadingWrap>
                ) : (
                    <RefTagWrap>
                        <RefTagBody>
                            <TreeItemContainer
                                depth={0}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    props.onSelectItems([]);
                                    tree?.current.unselectAllItems();
                                }}
                            >
                                <TreeItemMain isSelected={isFilterEmpty}>
                                    <TreeItemTitle treeId={props.treeId}>
                                        <IconBox>
                                            {isFilterEmpty ? (
                                                <Icon name={"check"} size={"10px"} color={"var(--color-DarkKey)"} />
                                            ) : (
                                                <Icon name={"folder"} size={"10px"} color={"#9FA8AB"} />
                                            )}
                                        </IconBox>
                                        <ItemText size={"regular"} isSelected={isFilterEmpty}>
                                            All References
                                        </ItemText>
                                    </TreeItemTitle>
                                </TreeItemMain>
                            </TreeItemContainer>
                            <TreeEnvironmentWrapper
                                treeId={props.treeId}
                                ref={tree}
                                dataProvider={dataProvider}
                                viewState={viewState}
                                multiSelect={false}
                                canDropOnFolder={true}
                                canDropOnNonFolder={true}
                                onSelectItems={(selectedItems) => {
                                    props.onSelectItems(selectedItems);
                                }}
                                onDrop={(updatedNodes) => {
                                    folderChangeOrder.mutate(updatedNodes, {
                                        onSuccess: (data, variables, context) => {
                                            setToast("폴더 순서가 변경되었습니다.", "info");
                                        },
                                        onError: (error) => {
                                            if (error.response.status === 400) {
                                                if (error.response.data.errors) {
                                                    Confirm(
                                                        "info",
                                                        "알림",
                                                        "이미 존재하는 폴더명입니다. 병합하시겠습니까?",
                                                        "병합",
                                                        () => {
                                                            folderMerge.mutate(error.response.data.instance, {
                                                                onSuccess: (data, variables, context) => {
                                                                    setToast("폴더가 병합되었습니다.", "info");
                                                                },
                                                                onError: (error) => {
                                                                    setToast(
                                                                        "폴더 병합에 실패했습니다. 잠시후 시도해주세요.",
                                                                        "error",
                                                                    );
                                                                },
                                                            });
                                                        },
                                                        () => {
                                                            // 변경 전 데이터를 불러와 업데이트
                                                            const items = {};
                                                            // 아이템을 `index`를 키로 하여 맵에 저장
                                                            folderQuery.data.forEach((folder) => {
                                                                items[folder.id] = {
                                                                    index: folder.id,
                                                                    name: folder.name,
                                                                    children: folder.children,
                                                                    order: folder.order,
                                                                    parent: folder.parent,
                                                                    isFolder: folder.children.length > 0,
                                                                    reference_count: folder.reference_count,
                                                                };
                                                            });

                                                            // 재귀적으로 트리를 정렬
                                                            const rootChildren = buildTreeWithOrdering(items, null).map(
                                                                (item) => item.index,
                                                            );

                                                            // `root` 노드에 최상위 레벨의 자식 노드를 추가
                                                            items["root"] = {
                                                                index: "root",
                                                                name: "root",
                                                                children: rootChildren,
                                                            };
                                                            setFolders(items);
                                                        },
                                                    );
                                                }
                                            }
                                        },
                                    });
                                }}
                                onCollapseItem={onCollapseItem}
                                onExpandItem={onExpandItem}
                                renderItem={({ item, info, title, arrow, depth, context, children }) => (
                                    <TreeItemContainer
                                        {...context.itemContainerWithChildrenProps}
                                        depth={Number(depth)}
                                        onMouseLeave={() => setShowContextMenu(false)}
                                    >
                                        <TreeItemMain
                                            {...context.itemContainerWithoutChildrenProps}
                                            {...context.interactiveElementProps}
                                            isDraggingOver={context.isDraggingOver}
                                            isSelected={context.isSelected}
                                        >
                                            {item.isFolder ? (
                                                <TreeItemArrow
                                                    {...context.arrowProps}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        if (context.isExpanded) {
                                                            tree.current?.collapseItem(item.index);
                                                        } else {
                                                            tree.current?.expandItem(item.index);
                                                        }
                                                    }}
                                                >
                                                    <IconWrap>
                                                        <Icon
                                                            name={
                                                                context.isExpanded
                                                                    ? "folderDownward"
                                                                    : "folderRightward"
                                                            }
                                                            size={"10px"}
                                                        />
                                                    </IconWrap>
                                                </TreeItemArrow>
                                            ) : (
                                                <BlankBox />
                                            )}

                                            <TreeItemTitle treeId={props.treeId}>
                                                <IconBox>
                                                    {context.isSelected ? (
                                                        <Icon
                                                            name={"check"}
                                                            size={"10px"}
                                                            color={"var(--color-DarkKey)"}
                                                        />
                                                    ) : (
                                                        <Icon name={"folder"} size={"10px"} color={"#9FA8AB"} />
                                                    )}
                                                </IconBox>
                                                <TitleWrapper>
                                                    <TextWrapper>
                                                        <ItemText isSelected={context.isSelected}>{title}</ItemText>
                                                    </TextWrapper>
                                                    <ItemCountText>&nbsp;{item.reference_count}</ItemCountText>
                                                </TitleWrapper>
                                            </TreeItemTitle>
                                            <TreeItemKnob
                                                className={`tree-knob-${item.index}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    setShowContextMenu(
                                                        item.index === showContextMenu ? false : item.index,
                                                    );
                                                }}
                                            >
                                                {showTooltip ? (
                                                    <Tooltip message={"더보기"}>
                                                        <IconWrap
                                                            onMouseEnter={() => setShowTooltip(true)}
                                                            onMouseLeave={() => setShowTooltip(false)}
                                                        >
                                                            <Icon name={"submenu"} />
                                                        </IconWrap>
                                                    </Tooltip>
                                                ) : (
                                                    <IconWrap
                                                        onMouseEnter={() => setShowTooltip(true)}
                                                        onMouseLeave={() => setShowTooltip(false)}
                                                    >
                                                        <Icon name={"submenu"} />
                                                    </IconWrap>
                                                )}
                                                {showContextMenu === item.index && (
                                                    <ContextMenu
                                                        show={true}
                                                        translate={"-92px, 0px"}
                                                        minWidth={"70px"}
                                                        items={[
                                                            {
                                                                name: "New Folder",
                                                                action: () => {
                                                                    props.setShowFolderAddModal({
                                                                        type: "create",
                                                                        parentIndex: item.index,
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                name: "Rename",
                                                                action: () => {
                                                                    props.setShowFolderAddModal({
                                                                        type: "update",
                                                                        item: item,
                                                                    });
                                                                },
                                                            },
                                                            {
                                                                name: "Delete",
                                                                action: () => {
                                                                    removeItem([item.index]);
                                                                },
                                                            },
                                                        ]}
                                                        onClose={() => setShowContextMenu(false)}
                                                        triggerClassName={`tree-knob-${item.index}`}
                                                    />
                                                )}
                                            </TreeItemKnob>
                                        </TreeItemMain>
                                        <TreeItemChildren>{children}</TreeItemChildren>
                                    </TreeItemContainer>
                                )}
                            />
                        </RefTagBody>
                    </RefTagWrap>
                )
            }
            {props.showFolderAddModal.type && (
                <AbstractModal
                    modalTitle={props.showFolderAddModal.type === "create" ? "Create Folder" : "Rename"}
                    width={480}
                    exitModal={(e) =>
                        props.setShowFolderAddModal({
                            type: "",
                        })
                    }
                >
                    <FolderEditModal
                        exitModal={(e) =>
                            props.setShowFolderAddModal({
                                type: "",
                            })
                        }
                        onSelectItems={props.onSelectItems}
                        expandItem={(item) => {
                            tree.current?.expandItem(item);
                        }}
                        mutation={props.showFolderAddModal.type === "create" ? folderCreate : folderUpdate}
                        context={props.showFolderAddModal}
                    />
                </AbstractModal>
            )}
        </>
    );
}

export default ReferenceFilterFolderView;
