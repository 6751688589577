import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";

import { genericEnterKeyDown } from "utils/validation";

const Label = styled.label`
    position: relative;
    display: inline-grid;
    vertical-align: top;
    align-items: center;
    padding: 4px 0px;
    width: auto;
    min-width: 160px;
    font-size: 13px;
    font-weight: 500;
    background-color: transparent;
    border-radius: 4px;

    &::after {
        content: attr(data-value) " ";
        visibility: hidden;
        white-space: pre-wrap;
        grid-area: 1 / 2;
        font: inherit;
        margin: 0;
        resize: none;
        background: none;
        appearance: none;
        border: none;
    }
`;

const Input = styled.input`
    grid-area: 1 / 2;
    padding: 4px 0px;
    min-width: 160px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-Black);
    background: none;
    appearance: none;
    border: none;
    border-radius: 4px;
    outline: #dce1e7;
    text-align: center;
    resize: none;
    transition: all 50ms ease-in;

    &:hover {
        background-color: var(--color-Base1);
    }

    &:focus {
        background-color: var(--color-Base1);
        border: solid 1px var(--color-Outline);
    }
`;

function ReviewTitleInput(props) {
    const [inputValue, setInputValue] = useState("");
    const [oldValue, setOldValue] = useState("");
    const labelRef = useRef(null);
    const inputRef = useRef(null);

    useEffect(() => {
        if (props.bibliography.display_name) {
            setInputValue(props.bibliography.display_name);
        }
    }, [props.bibliography]);

    const handleFocus = (e) => {
        setOldValue(inputValue);
    };

    const handleChange = (e) => {
        const input_value = e.target.value;

        if (input_value.length > 255) {
            Alert("warn", "에러", "255자 이상 입력할 수 없습니다.");
            return;
        }

        setInputValue(input_value);
    };

    const handleBlur = (e) => {
        saveValue();
    };

    const handleKeyDown = (e) => {
        genericEnterKeyDown(e, () => {
            e.target.blur();
        });
        if (e.key === "Escape") {
            setInputValue(oldValue);
        }
    };

    const saveValue = () => {
        if (!inputValue.trim()) {
            Alert("warn", "에러", "필수 입력 정보입니다.");
            setInputValue(oldValue);
            return;
        }

        if (inputValue !== oldValue) {
            props.bibliographyMutation.mutate({
                id: props.bibliography.id,
                display_name: inputValue,
            });
        }
    };

    return (
        <Label ref={labelRef} data-value={inputValue}>
            <Input
                ref={inputRef}
                size={2}
                maxLength={255}
                value={inputValue}
                onFocus={handleFocus}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                onBlur={handleBlur}
                placeholder="참고문헌 표시이름"
            />
        </Label>
    );
}

export default ReviewTitleInput;
