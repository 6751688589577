import React, { useState } from "react";

import styled from "styled-components";

import GeneralText from "components/atoms/text/GeneralText";
import StyledText from "components/atoms/text/StyledText";
import TitleText from "components/atoms/text/TitleText";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import Constants from "utils/constants";

const Container = styled.div`
    width: 100%;
    max-height: 80vh;
    overflow-y: auto;
`;

const PlanInfoWrap = styled.div`
    margin: 40px 0 0 16px;
`;

const InfoItem = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    width: 100%;
    height: 32px;
    border-bottom: dashed 1px var(--color-Line);

    &:last-child {
        margin: 0;
    }
`;

const Row = styled.div`
    display: flex;
    align-items: center;
`;

const SubInfoBox = styled.div`
    margin-top: 40px;
    padding: 8px 16px;
    width: 100%;
    background-color: var(--color-InfoBox);
    align-items: center;
    border-radius: 8px;
`;

const InfoListWrap = styled.ul`
    margin: 0;
    padding: 0 0 0 16px;
`;

const SubInfoItem = styled.li`
    margin-bottom: 8px;
    line-height: 1.4;
    text-align: justify;
    color: var(--color-SubBlack);

    &:last-child {
        margin-bottom: 0;
    }
`;

const Wrapper = styled.div`
    margin: 40px 0 0 16px;
`;

const OptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

const Option = styled.label`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    border: ${(props) => (props.selected ? "2px solid var(--color-Button1)" : "1px solid #ccc")};

    &:last-child {
        margin-bottom: 0;
    }
`;

const RadioButton = styled.input`
    margin-right: 10px;
    accent-color: var(--color-Button1);
`;

const OptionText = styled(StyledText)`
    font-size: 1.2rem;
    font-weight: 500;
`;

const Description = styled(StyledText)`
    font-size: 1.2rem;
    color: #666;
    margin: 5px 0;
    font-weight: 500;
`;

const OptionTitleText = styled(StyledText)`
    font-size: 1.4rem;
    font-weight: 500;
    margin: 40px 0 20px 0;
    padding: 0 0 0 8px;
    border-left: solid 2px var(--color-TitleBar);
`;

const subTitleStyle = {
    size: "subRegular",
    fontWeight: "regular",
    color: "var(--color-Grey1)",
};

const contentStyle = {
    size: "regular",
    color: "var(--color-Black)",
};

const listStyle = {
    size: "small",
    textAlign: "justify",
};

function PlanInfoModal(props) {
    const [isAutoPayment, setIsAutoPayment] = useState(true);

    const handleChange = (event) => {
        setIsAutoPayment(event.target.value === "true");
    };

    return (
        <Container>
            <TitleText withBarType size={"small"}>
                요금제 정보
            </TitleText>
            <PlanInfoWrap>
                <InfoItem>
                    <TitleText {...subTitleStyle}>요금제</TitleText>
                    <GeneralText {...contentStyle}>{props.planName}</GeneralText>
                </InfoItem>
                <InfoItem>
                    <TitleText {...subTitleStyle}>구독 요금</TitleText>
                    <Row>
                        <GeneralText {...contentStyle}>
                            {props.amount === 0 ? "무료" : `${props.amount} 원`}
                        </GeneralText>
                    </Row>
                </InfoItem>
                <InfoItem>
                    <TitleText {...subTitleStyle}>결제일</TitleText>
                    <Row>
                        {props.planId === Constants.FREE_PLAN ? (
                            <GeneralText {...contentStyle}>없음</GeneralText>
                        ) : (
                            <>
                                <GeneralText {...contentStyle} margin={"0 4px 0 0"}>
                                    매월
                                </GeneralText>
                                <GeneralText {...contentStyle}>{props.today}</GeneralText>
                                <GeneralText {...contentStyle} margin={"0 0 0 2px"}>
                                    일
                                </GeneralText>
                            </>
                        )}
                    </Row>
                </InfoItem>
            </PlanInfoWrap>

            {props.planId === Constants.FREE_PLAN ? (
                <SubInfoBox>
                    <InfoListWrap>
                        <SubInfoItem>
                            <GeneralText {...listStyle}>
                                무료 요금제는 저장 공간이 작고, AI의 텍스트 생성 기능에 제한이 있습니다.
                            </GeneralText>
                        </SubInfoItem>
                        <SubInfoItem>
                            <GeneralText {...listStyle}>
                                유료 구독 중 무료로 변경하면, 잔여 기간의 서비스 요금은 환불됩니다. 전환 당일은 서비스
                                이용일로 변경됩니다.
                            </GeneralText>
                        </SubInfoItem>
                    </InfoListWrap>
                </SubInfoBox>
            ) : (
                <>
                    <OptionTitleText>결제 옵션</OptionTitleText>
                    <Wrapper>
                        <OptionWrapper>
                            <Option selected={isAutoPayment}>
                                <RadioButton
                                    type="radio"
                                    value={true}
                                    checked={isAutoPayment}
                                    onChange={handleChange}
                                />
                                <div>
                                    <OptionText>정기 결제</OptionText>
                                    <Description>
                                        - 매월 결제일에 자동 결제되어, 유료 플랜을 계속 유지할 수 있습니다.
                                    </Description>
                                    <Description>- 언제든 설정에서 정기 결제를 해지할 수 있습니다.</Description>
                                </div>
                            </Option>
                            <Option selected={!isAutoPayment}>
                                <RadioButton
                                    type="radio"
                                    value={false}
                                    checked={!isAutoPayment}
                                    onChange={handleChange}
                                />
                                <div>
                                    <OptionText>일반 결제</OptionText>
                                    <Description>- 1회 결제로 한 달간 유료 플랜을 사용할 수 있습니다.</Description>
                                    <Description>
                                        - 자동 결제되지 않으며, 구독 기간이 끝나면 구독 해지됩니다.
                                    </Description>
                                </div>
                            </Option>
                        </OptionWrapper>
                    </Wrapper>

                    <SubInfoBox>
                        <InfoListWrap>
                            <SubInfoItem>
                                <GeneralText {...listStyle}>
                                    쿠폰은 설정&gt;결제 메뉴에서 사용하실 수 있습니다.
                                </GeneralText>
                            </SubInfoItem>
                        </InfoListWrap>
                    </SubInfoBox>
                </>
            )}

            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"구독"}
                leftOnClick={props.exitModal}
                rightOnClick={() => props.subscribeAct(isAutoPayment)}
            />
        </Container>
    );
}

export default PlanInfoModal;
