import React, { useEffect, useRef, useState } from "react";

import styled from "styled-components";

import FormInput from "components/molecules/FormInput";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { useResearchCreate } from "hooks/queries/useResearch";
import { useUser } from "hooks/queries/useUser";

import { genericEnterKeyDown } from "utils/validation";

const Container = styled.div`
    width: 100%;
`;

function ResearchAddModal(props) {
    const [displayName, setDisplayName] = useState("");
    const inputRef = useRef(null);
    const researchMutation = useResearchCreate();
    const userQuery = useUser();
    const [user, setUser] = useState({});

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
    }, [userQuery.data]);

    const addResearch = () => {
        researchMutation.mutate(
            {
                display_name: displayName,
                ai_response_language: user.env_ai_language,
                citation_style: user.env_citation_style,
            },
            {
                onSuccess: (data) => {
                    props.onSuccess(data);
                },
                onError: (error) => {
                    console.log(error);
                },
            },
        );
    };

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <Container>
            <FormInput
                labelDp="none"
                placeholder="Research Name"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                onKeyDown={(e) => {
                    genericEnterKeyDown(e, addResearch);
                }}
                ref={inputRef}
            />
            <AbstractModalFooter
                leftBtnText={"Cancle"}
                rightBtnText={"Create"}
                leftOnClick={props.exitModal}
                rightOnClick={() => {
                    addResearch();
                }}
            />
        </Container>
    );
}

export default ResearchAddModal;
