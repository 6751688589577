import React, { useEffect, useState } from "react";

import styled from "styled-components";

import Icon from "../../atoms/Icon";
import Button from "../../atoms/button/Button";
import AbstractModal from "./AbstractModal";
import TagEditModal from "./TagEditModal";

import GeneralText from "components/atoms/text/GeneralText";
import useToast from "components/atoms/toast/useToast";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { useTagCreate, useTagReferenceCreate, useTags } from "hooks/queries/useBibliographies";

const Container = styled.div`
    width: 100%;
`;

const MessageLine = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 24px 0 0 0;
    width: 100%;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px 0 40px 0;
    width: 100%;
    height: 320px;
`;

const NormalView = styled.div`
    display: block;
    padding: 8px;
    width: 100%;
    height: 320px;
    max-height: 320px;
    background-color: var(--color-Base1);
    border-radius: 4px;
    overflow: auto;
`;

const BlankView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const TagListWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
`;

const TagItem = styled.div`
    margin: 0 4px 4px 0;
    padding: 6px;
    max-width: 240px;
    height: 28px;
    background-color: ${(props) => (props.selected ? "var(--color-TagActive)" : "var(--color-Tag)")};
    border: solid 1px transparent;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: 120ms ease-in;

    &:hover {
        border: solid 1px var(--color-Key);
    }
`;

const TagAddButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 16px 0 0 0;
`;

function ReferenceTagMultiAddModal(props) {
    const { exitModal, referenceIds } = props;

    const tagsQuery = useTags();
    const tagCreate = useTagCreate();
    const [tags, setTags] = useState([]);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [showTagAddModal, setShowTagAddModal] = useState(false);
    const { setToast } = useToast();

    useEffect(() => {
        if (tagsQuery.data) {
            setTags(tagsQuery.data);
        }
    }, [tagsQuery.data]);

    const tagReferenceCreate = useTagReferenceCreate();

    const handleCheck = (id) => {
        if (selectedTagIds.includes(id)) {
            setSelectedTagIds(selectedTagIds.filter((item) => item !== id));
        } else {
            setSelectedTagIds([...selectedTagIds, id]);
        }
    };

    const handleAdd = () => {
        tagReferenceCreate.mutate(
            {
                reference_ids: referenceIds,
                tag_ids: selectedTagIds,
                recommended_tags: [],
                delete_old_tag: false,
                delete_old_reference: false,
            },
            {
                onSuccess: () => {
                    exitModal();
                    setToast("선택한 태그가 적용되었습니다.", "info");
                },
                onError: (error) => {
                    setToast("태그 연결에 실패했습니다. 잠시후 시도해주세요.", "error");
                },
            },
        );
    };

    return (
        <Container>
            <MessageLine>
                <GeneralText size={"regular"}>참고문헌에 적용할 태그를 선택해주세요.</GeneralText>
            </MessageLine>

            <TagAddButtonWrap>
                <Button
                    width={"100px"}
                    height={"24px"}
                    bgColor={"var(--color-Button1)"}
                    hoverBgColor={"var(--color-ButtonHover1)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"새 태그"}
                    onClick={(e) => setShowTagAddModal(true)}
                >
                    <Icon name={"plus"} size={"12"} color={"var(--color-White)"} />
                </Button>
            </TagAddButtonWrap>

            <ContentsWrapper>
                <NormalView>
                    {tags.length > 0 ? (
                        <TagListWrap>
                            {tags.map((tag, index) => (
                                <TagItem
                                    key={index}
                                    selected={selectedTagIds.includes(tag.id)}
                                    onClick={(e) => handleCheck(tag.id)}
                                >
                                    <GeneralText ellipsis size={"small"} color={"var(--color-Black)"}>
                                        {tag.name}
                                    </GeneralText>
                                </TagItem>
                            ))}
                        </TagListWrap>
                    ) : (
                        <BlankView>
                            <GeneralText size={"small"} textAlign={"center"} color={"var(--color-DisabledText)"}>
                                아직 태그가 없습니다.
                            </GeneralText>
                        </BlankView>
                    )}
                </NormalView>
            </ContentsWrapper>

            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"적용"}
                leftOnClick={props.exitModal}
                rightOnClick={handleAdd}
            />

            {showTagAddModal && (
                <AbstractModal modalTitle={"Create Tag"} width={480} exitModal={(e) => setShowTagAddModal(false)}>
                    <TagEditModal
                        exitModal={(e) => setShowTagAddModal(false)}
                        item={showTagAddModal}
                        mutation={tagCreate}
                    />
                </AbstractModal>
            )}
        </Container>
    );
}

export default ReferenceTagMultiAddModal;
