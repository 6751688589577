import React, { useEffect, useState } from "react";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";

import AbstractModal from "components/page/modal/AbstractModal";
import AbstractModalFooter from "components/page/modal/AbstractModalFooter";
import ResearchAddModal from "components/page/modal/ResearchAddModal";

import { useResearchReferencesCreate, useResearches } from "hooks/queries/useResearch";

const Container = styled.div`
    width: 100%;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 8px 0 40px 0;
    padding: 6px;
    width: 100%;
    height: 320px;
    max-height: 320px;
    background-color: var(--color-Base1);
`;

const ResearchListWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;
    height: auto;
    overflow: auto;
`;

const ResearchItem = styled.div`
    margin: 2px;
    padding: 4px;
    max-width: 430px;
    height: 28px;
    background-color: ${(props) => (props.selected ? "var(--color-ResearchActive)" : "var(--color-Research)")};
    border: solid 1px transparent;
    border-radius: 2px;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    transition: border 80ms ease-in;

    &:hover {
        border: solid 1px var(--color-Key);
    }
`;

const BlankView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const ResearchAddButtonWrap = styled.div`
    display: flex;
    justify-content: flex-end;
    margin: 16px 0 0 0;
`;

function ReferenceCitationMultiAddModal(props) {
    const { exitModal, referenceIds, referenceBibJsons } = props;
    const [addResearchModal, setAddResearchModal] = useState(false);
    const researchesQuery = useResearches();

    const [researches, setResearches] = useState([]);
    const [selectedResearchIds, setSelectedResearchIds] = useState([]);

    const researchReferenceCreateMutation = useResearchReferencesCreate();

    useEffect(() => {
        if (researchesQuery.data) {
            setResearches(researchesQuery.data);
        }
    }, [researchesQuery.data]);

    const handleCheck = (id) => {
        if (selectedResearchIds.includes(id)) {
            setSelectedResearchIds(selectedResearchIds.filter((item) => item !== id));
        } else {
            setSelectedResearchIds([...selectedResearchIds, id]);
        }
    };

    const handleAdd = () => {
        if (referenceIds) {
            researchReferenceCreateMutation.mutate({
                reference_ids: referenceIds,
                research_ids: selectedResearchIds,
                delete_old_research: false,
                delete_old_reference: false,
            });
        } else {
            researchReferenceCreateMutation.mutate({
                reference_bib_jsons: referenceBibJsons,
                research_ids: selectedResearchIds,
                delete_old_research: false,
                delete_old_reference: false,
            });
        }
        exitModal();
    };

    return (
        <Container>
            <GeneralText size={"regular"}>참고문헌을 연결할 리서치를 선택해주세요.</GeneralText>
            <ResearchAddButtonWrap>
                <Button
                    width={"100px"}
                    height={"24px"}
                    bgColor={"var(--color-Button1)"}
                    hoverBgColor={"var(--color-ButtonHover1)"}
                    fontColor={"var(--color-White)"}
                    buttonText={"리서치 추가"}
                    onClick={(e) => setAddResearchModal(!addResearchModal)}
                >
                    <Icon name={"plus"} size={"12"} color={"var(--color-White)"} />
                </Button>
            </ResearchAddButtonWrap>
            <ContentsWrapper>
                {researches?.length > 0 ? (
                    <ResearchListWrap>
                        {researches.map((research, index) => (
                            <ResearchItem
                                key={index}
                                onClick={(e) => handleCheck(research.id)}
                                selected={selectedResearchIds.includes(research.id)}
                            >
                                <GeneralText ellipsis size={"small"} color={"var(--color-Black)"}>
                                    {research.display_name}
                                </GeneralText>
                            </ResearchItem>
                        ))}
                    </ResearchListWrap>
                ) : (
                    <BlankView>
                        <GeneralText size={"small"} textAlign={"center"} color={"var(--color-DisabledText)"}>
                            아직 리서치가 없습니다.
                        </GeneralText>
                    </BlankView>
                )}
            </ContentsWrapper>
            <AbstractModalFooter
                leftBtnText={"취소"}
                rightBtnText={"적용"}
                leftOnClick={props.exitModal}
                rightOnClick={handleAdd}
            />
            {addResearchModal && (
                <AbstractModal
                    modalTitle="Create Research"
                    width={480}
                    exitModal={(e) => setAddResearchModal(!addResearchModal)}
                >
                    <ResearchAddModal
                        exitModal={(e) => setAddResearchModal(!addResearchModal)}
                        onSuccess={() => {
                            setAddResearchModal(false);
                        }}
                    />
                </AbstractModal>
            )}
        </Container>
    );
}

export default ReferenceCitationMultiAddModal;
