import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import AbstractModal from "components/page/modal/AbstractModal";
import PaymentBillingHistoryModal from "components/page/modal/PaymentBillingHistoryModal";
import PlanGroupInfoModal from "components/page/modal/PlanGroupInfoModal";
import PlanGroupMemberModal from "components/page/modal/PlanGroupMemberModal";

import {
    usePaymentBillingHistory,
    usePaymentBillingKeyCreate,
    usePaymentBillingKeyDelete,
    usePaymentBillingKeys,
    usePaymentCoupon,
    usePaymentCouponRegister,
    usePaymentCouponUse,
    usePaymentPlanGroup,
    usePaymentPlanGroupUser,
    usePaymentPlanGroupUserUpdate,
    usePaymentPlanSubscriptionHistory,
    usePaymentPlanSubscriptionHistoryCreate,
} from "hooks/queries/usePayment";
import { useUser } from "hooks/queries/useUser";

import Constants from "utils/constants";
import { getDateFromString } from "utils/dateUtil";
import { genericEnterKeyDown, isGroupPlanUser } from "utils/validation";

import moment from "moment/moment";

const Container = styled.div`
    display: ${(props) => (props.isActive ? "block" : "none")};
    width: 100%;
`;

const SectionContainer = styled.div`
    margin: 40px 0 0 0;
    padding: ${(props) => props.padding};
    width: 100%;
`;

const Category = styled.div`
    display: flex;
    flex-direction: ${(props) => props.flexDirection || "row"};
    justify-content: ${(props) => props.justifyContent || "space-between"};
    align-items: ${(props) => props.alignItems || "center"};
    margin: ${(props) => props.margin || "0 0 24px 0"};
    height: ${(props) => props.height || "32px"};
    width: 100%;
`;

const TabButtonWrap = styled.div`
    display: flex;
    align-items: center;
    width: auto;
    height: 32px;
`;

const TabButton = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 160px;
    height: 32px;
    cursor: pointer;
`;

const TabBar = styled.span`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    width: 160px;
    height: 2px;
    background-color: ${(props) => (props.isActive ? "var(--color-Key)" : "var(--color-DisabledButton)")};
`;

const DivideSection = styled.div`
    margin: 40px 0;
    width: 100%;
    height: 1px;
    border-bottom: solid 1px var(--color-Line);
`;

const ContentsWrapper = styled.div`
    margin: 40px 0 0 0;
    width: 100%;
`;

const ContentsInner = styled.div`
    padding: 0 0 0 24px;
`;

const Row = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
`;

const TableWrap = styled.div`
    margin: 8px 0 0 0;
    width: 100%;
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    overflow: hidden;
`;

const Table = styled.table`
    width: 100%;
    padding: 0 0 160px 0;
`;

const Tr = styled.tr`
    border-bottom: solid 1px var(--color-Outline);
    overflow: hidden;

    &:last-child {
        border-bottom: transparent;
    }
`;

const Th = styled.th`
    padding: 12px 16px;
    width: ${(props) => props.width};
    background-color: #f1f6f9;
    text-align: ${(props) => props.textAlign || "center"};
    border-right: solid 1px var(--color-Outline);

    &:last-child {
        border-right: transparent;
    }
`;

const Td = styled.td`
    padding: ${(props) => props.padding || "12px 16px"};
    background-color: var(--color-White);
    text-align: ${(props) => props.textAlign || "center"};
    border-right: solid 1px var(--color-Outline);

    &:last-child {
        border-right: transparent;
    }
`;

const DateText = styled.span`
    margin: ${(props) => props.margin};
`;

const Amount = styled.span`
    margin: 0 4px 0 0;
`;

const ListView = styled.div`
    display: ${(props) => (props.isActive ? "block" : "none")};
    margin: 40px 0 0 0;
    padding: 0 0 0 24px;
`;

const HistoryView = styled.div`
    display: ${(props) => (props.isActive ? "block" : "none")};
    margin: 40px 0 0 0;
    padding: 0 0 0 24px;
`;

const InputWrap = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    margin: 0 0 0 16px;
    height: 32px;
`;

const CouponInput = styled.input`
    padding: 0 8px;
    width: calc(100% - 56px);
    height: 32px;
    background-color: var(--color-White);
    border: solid 1px var(--color-Grey2);
    border-radius: 4px;
    outline: none;
    font-size: 12px;
    font-weight: 400;
    color: var(--color-SubBlack);
    transition: all 120ms ease-in;

    &:hover {
        border: solid 1px var(--color-Key);
    }

    &:disabled {
        background-color: var(--color-DisabledInput);
    }
`;

const StrageWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 162px);
`;

const BarWrap = styled.div`
    position: relative;
    flex: 1;
    max-width: calc(100% - 280px);
    height: 6px;
    border-radius: 3px;
    background-color: var(--color-Grey3);
    overflow: hidden;
`;

const Guage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: ${(props) => props.guage}%;
    height: 8px;
    background-color: var(--color-Key);
`;

const TextWrap = styled.div`
    display: flex;
    align-items: center;
    margin: 0;
    width: auto;
    text-align: right;
`;

const UsingCapacity = styled.span`
    margin: 0 0 0 4px;
`;

const barTitleStyle = {
    size: "small",
    width: "200px",
    fontWeight: "regular",
};

const subTitleStyle = {
    size: "subRegular",
    fontWeight: "regular",
    color: "var(--color-Grey1)",
};

const GroupSection = styled.section`
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    padding: 16px;
    margin-top: 20px;
`;

const GroupTitleSection = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
`;

const GroupTitle = styled.h2`
    font-size: 1.4rem;
    font-weight: 400;
`;

const GroupDetailList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 20px 0;
`;

const GroupDetailItem = styled.li`
    margin: 10px 0;
    font-size: 12px;
    font-weight: 400;
    color: #555;
`;

const GroupItemLabel = styled.span`
    font-size: 12px;
    font-weight: 400;
`;

const GroupItemValue = styled.span`
    float: right;
`;

const NoticeSection = styled.section`
    background-color: #f9f9f9;
    border-radius: 4px;
    padding: 12px;
    margin-top: 20px;
    font-size: 0.9em;
    color: #666;
`;

const NoticeList = styled.ul`
    list-style: none;
    padding: 0;
`;

const NoticeItem = styled.li`
    &:before {
        content: "•";
        margin-right: 8px;
        color: #666;
    }
`;

const ButtonGroup = styled.div`
    display: flex;
    gap: 8px; // 버튼 사이 간격을 8px로 설정
`;

const GroupItemValues = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px; // 요소 사이 간격을 8px로 설정
`;

const DetailRow = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

function TabPaymentView(props) {
    const navigate = useNavigate();

    const [user, setUser] = useState({});
    const [subscribeHistory, setSubscribeHistory] = useState({});
    const [billingKey, setBillingKey] = useState({ is_deleted: true });
    const [paymentHistory, setPaymentHistory] = useState([]);
    const [usedCouponList, setUsedCouponList] = useState([]);
    const [unUsedCouponList, setUnUsedCouponList] = useState([]);
    const [coupon, setCoupon] = useState("");
    const [showPlanGroupInfoModal, setShowPlanGroupInfoModal] = useState(false);
    const [showPlanGroupMemberModal, setShowPlanGroupMemberModal] = useState(false);
    const [paymentPlanGroup, setPaymentPlanGroup] = useState({});
    const [paymentPlanGroupUser, setPaymentPlanGroupUser] = useState([]);

    const [showPaymentHistoryModal, setShowPaymentHistoryModal] = useState(false);
    const [paymentBillingHistory, setPaymentBillingHistory] = useState(undefined);

    const billingKeyQuery = usePaymentBillingKeys();

    const userQuery = useUser();
    const subscribeHistoryQuery = usePaymentPlanSubscriptionHistory();
    const paymentHistoryQuery = usePaymentBillingHistory();
    const billingKeyMutation = usePaymentBillingKeyCreate();
    const billingKeyDelete = usePaymentBillingKeyDelete();
    const couponQuery = usePaymentCoupon();
    const couponUse = usePaymentCouponUse();
    const couponRegister = usePaymentCouponRegister();
    const subscribeHistoryMutation = usePaymentPlanSubscriptionHistoryCreate();

    const paymentPlanGroupQuery = usePaymentPlanGroup();
    const paymentPlanGroupUserQuery = usePaymentPlanGroupUser();
    const paymentPlanGroupUserUpdateMutation = usePaymentPlanGroupUserUpdate();

    useEffect(() => {
        const jquery = document.createElement("script");
        jquery.src = "https://code.jquery.com/jquery-1.12.4.min.js";
        const iamport = document.createElement("script");
        iamport.src = "https://cdn.iamport.kr/js/iamport.payment-1.1.8.js";
        document.head.appendChild(jquery);
        document.head.appendChild(iamport);
        return () => {
            document.head.removeChild(jquery);
            document.head.removeChild(iamport);
        };
    }, []);

    useEffect(() => {
        if (billingKeyQuery.data) {
            if (billingKeyQuery.data.length === 0) {
                setBillingKey({ is_deleted: true });
            } else {
                setBillingKey(billingKeyQuery.data[billingKeyQuery.data.length - 1]);
            }
        }
        if (userQuery.data) {
            setUser(userQuery.data);
        }
        if (subscribeHistoryQuery.data) {
            setSubscribeHistory(subscribeHistoryQuery.data[subscribeHistoryQuery.data.length - 1]);
        }
        if (paymentHistoryQuery.data) {
            setPaymentHistory(paymentHistoryQuery.data);
        }
        if (couponQuery.data) {
            setUsedCouponList(couponQuery.data.filter((coupon) => coupon.used_at));
            setUnUsedCouponList(couponQuery.data.filter((coupon) => !coupon.used_at));
        }
        if (paymentPlanGroupUserQuery.data) {
            setPaymentPlanGroupUser(paymentPlanGroupUserQuery.data);
        }
    }, [
        billingKeyQuery.data,
        userQuery.data,
        subscribeHistoryQuery.data,
        paymentHistoryQuery.data,
        couponQuery.data,
        paymentPlanGroupUserQuery.data,
    ]);

    useEffect(() => {
        setPaymentPlanGroup(paymentPlanGroupQuery.data);
    }, [paymentPlanGroupQuery.data]);

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const [tabActive, setTabActive] = useState("list");

    const thStyle = {
        size: "small",
        color: "var(--color-Grey1)",
    };

    const tdStyle = {
        size: "small",
        margin: "0 0 4px 0",
        color: "var(--color-Black)",
    };

    const blankStyle = {
        size: "regular",
        color: "var(--color-Grey1)",
    };

    const subscribeGroupPlan = () => {
        const { IMP } = window;
        IMP.init("imp65161342");
        let randomNumber = Math.floor(Math.random() * 90000) + 10000;
        let merchant_uid = `subscribe_${user.email.split("@")[0]}_${randomNumber}`;
        let customer_uid = `${user.email.split("@")[0]}_${randomNumber}`;

        const callback = (response) => {
            document.getElementById("iamport-modal").style.display = "none";
            if (response.success) {
                billingKeyMutation.mutate(response, {
                    onSuccess: (billingKey) => {
                        subscribeHistoryMutation.mutate(
                            {
                                plan: paymentPlanGroup.plan_id,
                                merchant_uid: merchant_uid,
                                billing_key: billingKey.id,
                            },
                            {
                                onSuccess: () => {
                                    Alert("info", "구독", "결제가 완료되었습니다.");
                                    subscribeHistoryQuery.refetch();
                                    billingKeyQuery.refetch();
                                    userQuery.refetch();
                                    paymentHistoryQuery.refetch();
                                    paymentPlanGroupQuery.refetch();
                                    paymentPlanGroupUserQuery.refetch();
                                },
                                onError: () => {
                                    Alert(
                                        "warn",
                                        "에러",
                                        "구독 정보 변경 중 오류가 발생했습니다. 관리자에게 문의해주세요.",
                                    );
                                },
                            },
                        );
                    },
                    onError: () => {
                        Alert("warn", "에러", "결제 정보 등록 중 오류가 발생했습니다. 결제 정보를 다시 확인해주세요.");
                    },
                });
            } else {
                if (response.error_msg === "사용자가 결제를 취소하셨습니다") {
                    return;
                }
                Alert("warn", "에러", "결제 중 오류가 발생했습니다. 결제 정보를 다시 확인해주세요.");
            }
        };
        document.getElementById("iamport-modal").style.display = "block";

        IMP.request_pay(
            {
                pg: `${process.env.REACT_APP_ENV !== "prd" ? "danal_tpay.9810030929" : "danal_tpay"}`,
                pay_method: "card",
                merchant_uid: merchant_uid,
                name: `자동결제 등록 > 단체 구독`,
                period: {
                    from: moment().format("YYYY-MM-DD"),
                    to: moment().add(1, "months").add(-1, "days").format("YYYY-MM-DD"),
                },
                amount: paymentPlanGroup.price,
                customer_uid: customer_uid,
                buyer_email: user.email,
            },
            callback,
        );

        setShowPlanGroupInfoModal(false);
    };

    const handleCardDelete = () => {
        Confirm("warn", "결제 수단 삭제", "등록된 결제 수단을 삭제하겠습니까?", "삭제", (e) => {
            billingKeyDelete.mutate(billingKey.id);
        });
    };

    return (
        <Container isActive={props.isActive}>
            {/* 구독중인 요금제 */}
            <SectionContainer>
                <Category margin="0" justifyContent="space-between">
                    <TitleText withBarType {...barTitleStyle}>
                        구독중인 요금제
                    </TitleText>
                    <Tooltip message={"요금 안내 화면으로 이동합니다."}>
                        <Button
                            onlyText
                            width={"100px"}
                            height={"32px"}
                            fontColor={"var(--color-White)"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            buttonText={"요금제 변경"}
                            onClick={(e) => navigate("/plan")}
                        />
                    </Tooltip>
                </Category>
                <ContentsWrapper>
                    <ContentsInner>
                        <Category margin="0 0 16px 0">
                            <TitleText {...subTitleStyle}>사용 중인 플랜</TitleText>
                            <Row>
                                <GeneralText size={"regular"} color={"var(--color-Black)"}>
                                    {subscribeHistory?.plan === Constants.FREE_PLAN
                                        ? "무료플랜"
                                        : subscribeHistory?.plan === Constants.PAID_PLAN
                                        ? "유료플랜"
                                        : !subscribeHistory?.plan
                                        ? ""
                                        : "단체 플랜"}
                                </GeneralText>
                            </Row>
                        </Category>
                        <Category margin="0 0 16px 0">
                            <TitleText {...subTitleStyle}>사용 가능한 저장공간</TitleText>
                            <StrageWrap>
                                <BarWrap>
                                    <Guage
                                        guage={
                                            (user.file_total_size /
                                                (user.plan_id === Constants.FREE_PLAN
                                                    ? 200 * Constants.MB
                                                    : 20 * Constants.GB)) *
                                            100
                                        }
                                    />
                                </BarWrap>
                                <TextWrap>
                                    <GeneralText size={"regular"} margin={"0 4px 0 0"}>
                                        사용 용량:
                                        <UsingCapacity>{formatBytes(user.file_total_size)}</UsingCapacity>
                                    </GeneralText>
                                    <GeneralText size={"regular"} margin={"0"}>
                                        / 전체 용량: {user.plan_id !== Constants.FREE_PLAN ? "20 GB" : "200 MB"}
                                    </GeneralText>
                                </TextWrap>
                            </StrageWrap>
                        </Category>
                        {(!isGroupPlanUser(user) || paymentPlanGroup?.admin_email === user.email) && (
                            <>
                                <Category margin="0 0 16px 0">
                                    <TitleText {...subTitleStyle}>구독 시작일</TitleText>
                                    <GeneralText size={"regular"} color={"var(--color-Black)"}>
                                        {getDateFromString(subscribeHistory?.started_at)}
                                    </GeneralText>
                                </Category>
                                <Category margin="0 0 16px 0">
                                    <TitleText {...subTitleStyle}>다음 결제일</TitleText>
                                    <Row>
                                        <GeneralText size={"regular"} color={"var(--color-Black)"}>
                                            {user.plan_id !== Constants.FREE_PLAN
                                                ? subscribeHistory?.next_payment_date
                                                : "없음"}
                                        </GeneralText>
                                        {user.plan_id !== Constants.FREE_PLAN && (
                                            <Button
                                                onlyText
                                                margin={"0 0 0 8px"}
                                                width={"56px"}
                                                height={"32px"}
                                                fontColor={"var(--color-White)"}
                                                bgColor={"var(--color-Button2)"}
                                                hoverBgColor={"var(--color-ButtonHover2)"}
                                                buttonText={"청구서"}
                                                onClick={(e) => {
                                                    setPaymentBillingHistory({ id: "bill" });
                                                    setShowPaymentHistoryModal(true);
                                                }}
                                            />
                                        )}
                                    </Row>
                                </Category>
                                <Category margin="0">
                                    <TitleText {...subTitleStyle}>결제 수단</TitleText>
                                    <Row>
                                        <GeneralText size={"regular"} color={"var(--color-Black)"}>
                                            {billingKey.is_deleted
                                                ? "등록된 결제 수단이 없습니다."
                                                : billingKey.card_number}
                                        </GeneralText>
                                        {!billingKey.is_deleted && (
                                            <Button
                                                onlyText
                                                margin={"0 0 0 8px"}
                                                width={"56px"}
                                                height={"32px"}
                                                fontColor={"var(--color-White)"}
                                                bgColor={"var(--color-Button2)"}
                                                hoverBgColor={"var(--color-ButtonHover2)"}
                                                buttonText={"삭제"}
                                                onClick={handleCardDelete}
                                            />
                                        )}
                                    </Row>
                                </Category>
                            </>
                        )}
                    </ContentsInner>
                </ContentsWrapper>
            </SectionContainer>

            {/* 단체 구독하기 */}
            {!isGroupPlanUser(user) && paymentPlanGroup?.admin_email === user.email && (
                // 단체 구독이 준비되었을 때
                <GroupSection>
                    <GroupTitleSection>
                        <GroupTitle>요청하신 단체 플랜이 준비되었습니다. 지금 구독을 시작해주세요.</GroupTitle>
                        <Button
                            onlyText
                            width={"100px"}
                            height={"32px"}
                            fontColor={"var(--color-White)"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            buttonText={"구독하기"}
                            onClick={(e) => setShowPlanGroupInfoModal(true)}
                        />
                    </GroupTitleSection>
                    <GroupDetailList>
                        <GroupDetailItem>
                            <GroupItemLabel>단체명</GroupItemLabel>
                            <GroupItemValue>{paymentPlanGroup?.name}</GroupItemValue>
                        </GroupDetailItem>
                        <GroupDetailItem>
                            <GroupItemLabel>관리자</GroupItemLabel>
                            <GroupItemValue>최대 {paymentPlanGroup?.max_member}명</GroupItemValue>
                        </GroupDetailItem>
                        <GroupDetailItem>
                            <GroupItemLabel>요금</GroupItemLabel>
                            <GroupItemValue>{paymentPlanGroup?.price} 원(매달)</GroupItemValue>
                        </GroupDetailItem>
                    </GroupDetailList>
                    <NoticeSection>
                        <NoticeList>
                            <NoticeItem>결제가 완료되면 현재 요금제가 구독 해지되고 단체 플랜이 시작됩니다.</NoticeItem>
                            <NoticeItem>
                                유료 구독 중인 경우, 유료 구독 요금은 지난 결제일로부터 경과한 일 수를 계산하여
                                환불됩니다. 환불 신청 당일도 일 수에 포함됩니다.
                            </NoticeItem>
                        </NoticeList>
                    </NoticeSection>
                </GroupSection>
            )}

            {!isGroupPlanUser(user) &&
                paymentPlanGroup?.admin_email !== user.email &&
                paymentPlanGroupUser.find((groupUser) => groupUser.email === user.email)?.invitation_status ===
                    "pending" && (
                    <GroupSection>
                        <GroupTitleSection>
                            <GroupTitle>단체 플랜 구독에 초대되었습니다. 지금 구독을 시작해주세요.</GroupTitle>
                            <ButtonGroup>
                                <Button
                                    onlyText
                                    width={"100px"}
                                    height={"32px"}
                                    fontColor={"var(--color-White)"}
                                    bgColor={"var(--color-Button2)"}
                                    hoverBgColor={"var(--color-ButtonHover2)"}
                                    buttonText={"거절하기"}
                                    onClick={(e) => {
                                        Confirm(
                                            "warn",
                                            "알림",
                                            "거절하겠습니까? 현재 구독 중인 요금제가 유지됩니다.",
                                            "거절하기",
                                            () => {
                                                paymentPlanGroupUserUpdateMutation.mutate({
                                                    id: paymentPlanGroupUser.find(
                                                        (groupUser) => groupUser.email === user.email,
                                                    ).id,
                                                    invitation_status: "rejected",
                                                });
                                            },
                                        );
                                    }}
                                />
                                <Button
                                    onlyText
                                    width={"100px"}
                                    height={"32px"}
                                    fontColor={"var(--color-White)"}
                                    bgColor={"var(--color-Button2)"}
                                    hoverBgColor={"var(--color-ButtonHover2)"}
                                    buttonText={"구독하기"}
                                    onClick={(e) =>
                                        subscribeHistoryMutation.mutate(
                                            {
                                                plan: paymentPlanGroup.plan_id,
                                            },
                                            {
                                                onSuccess: () => {
                                                    Alert("info", "구독", "구독 요금제가 변경되었습니다.");
                                                },
                                            },
                                        )
                                    }
                                />
                            </ButtonGroup>
                        </GroupTitleSection>
                        <GroupDetailList>
                            <GroupDetailItem>
                                <GroupItemLabel>단체명</GroupItemLabel>
                                <GroupItemValue>{paymentPlanGroup?.name}</GroupItemValue>
                            </GroupDetailItem>
                            <GroupDetailItem>
                                <GroupItemLabel>관리자</GroupItemLabel>
                                <GroupItemValue>{paymentPlanGroup?.admin_email}</GroupItemValue>
                            </GroupDetailItem>
                        </GroupDetailList>
                        <NoticeSection>
                            <NoticeList>
                                <NoticeItem>단체 플랜 구독시 현재 요금제가 구독 해지됩니다.</NoticeItem>
                                <NoticeItem>
                                    유료 구독 중인 경우, 유료 구독 요금은 지난 결제일로부터 경과한 일 수를 계산하여
                                    환불됩니다. 환불 신청 당일도 일 수에 포함됩니다.
                                </NoticeItem>
                            </NoticeList>
                        </NoticeSection>
                    </GroupSection>
                )}

            {isGroupPlanUser(user) && paymentPlanGroup?.admin_email === user.email && (
                <GroupSection>
                    <GroupTitleSection>
                        <GroupTitle>단체 플랜 관리</GroupTitle>
                        <Button
                            onlyText
                            width={"100px"}
                            height={"32px"}
                            fontColor={"var(--color-White)"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            buttonText={"구독 해지"}
                            onClick={(e) =>
                                Confirm(
                                    "warn",
                                    "알림",
                                    "구독을 해지하시겠습니까? 구독 요금은 지난 결제일로부터 경과한 일 수를 계산하여 환불되며, 구성원들의 요금제는 무료 플랜으로 변경됩니다.",
                                    "구독 해지",
                                    () => {
                                        subscribeHistoryMutation.mutate({
                                            plan: Constants.FREE_PLAN,
                                        });
                                    },
                                )
                            }
                        />
                    </GroupTitleSection>
                    <GroupDetailList>
                        <GroupDetailItem>
                            <GroupItemLabel>이름</GroupItemLabel>
                            <GroupItemValue>{paymentPlanGroup.name}</GroupItemValue>
                        </GroupDetailItem>
                        <GroupDetailItem>
                            <GroupItemLabel>구성원</GroupItemLabel>
                            <GroupItemValues>
                                <GroupItemValue>
                                    현재 {paymentPlanGroupUser.length}명/최대 {paymentPlanGroup?.max_member}명
                                </GroupItemValue>
                                <Button
                                    onlyText
                                    width={"40px"}
                                    height={"24px"}
                                    fontColor={"var(--color-White)"}
                                    bgColor={"var(--color-Button2)"}
                                    hoverBgColor={"var(--color-ButtonHover2)"}
                                    buttonText={"관리"}
                                    onClick={(e) => setShowPlanGroupMemberModal(true)}
                                />
                            </GroupItemValues>
                        </GroupDetailItem>
                    </GroupDetailList>
                </GroupSection>
            )}

            {isGroupPlanUser(user) && paymentPlanGroup?.admin_email !== user.email && (
                <GroupSection>
                    <GroupTitleSection>
                        <GroupTitle>단체 플랜 관리</GroupTitle>
                        <Button
                            onlyText
                            width={"100px"}
                            height={"32px"}
                            fontColor={"var(--color-White)"}
                            bgColor={"var(--color-Button2)"}
                            hoverBgColor={"var(--color-ButtonHover2)"}
                            buttonText={"나가기"}
                            onClick={(e) =>
                                Confirm(
                                    "warn",
                                    "알림",
                                    "단체 플랜에서 나가시겠습니까? 무료 플랜으로 구독 변경됩니다.",
                                    "나가기",
                                    () => {
                                        subscribeHistoryMutation.mutate({
                                            plan: Constants.FREE_PLAN,
                                        });
                                    },
                                )
                            }
                        />
                    </GroupTitleSection>
                    <GroupDetailList>
                        <GroupDetailItem>
                            <GroupItemLabel>이름</GroupItemLabel>
                            <GroupItemValue>{paymentPlanGroup?.name}</GroupItemValue>
                        </GroupDetailItem>
                        <GroupDetailItem>
                            <GroupItemLabel>시작일</GroupItemLabel>
                            <GroupItemValue>{getDateFromString(subscribeHistory?.started_at)}</GroupItemValue>
                        </GroupDetailItem>
                        <GroupDetailItem>
                            <GroupItemLabel>관리자</GroupItemLabel>
                            <GroupItemValue>{paymentPlanGroup?.admin_email}</GroupItemValue>
                        </GroupDetailItem>
                    </GroupDetailList>
                </GroupSection>
            )}

            <DivideSection />
            {/* 쿠폰 사용 내역 */}
            <SectionContainer>
                <Category margin="0">
                    <TitleText withBarType {...barTitleStyle}>
                        쿠폰
                    </TitleText>
                    <TabButtonWrap>
                        <TabButton onClick={() => setTabActive("list")}>
                            <GeneralText
                                size={"small"}
                                color={tabActive === "list" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                            >
                                사용 가능한 쿠폰
                            </GeneralText>
                            <TabBar isActive={tabActive === "list"} />
                        </TabButton>
                        <TabButton onClick={() => setTabActive("history")}>
                            <GeneralText
                                size={"small"}
                                color={tabActive === "history" ? "var(--color-Key)" : "var(--color-DisabledText)"}
                            >
                                쿠폰 사용 내역
                            </GeneralText>
                            <TabBar isActive={tabActive === "history"} />
                        </TabButton>
                    </TabButtonWrap>
                </Category>
                <ListView isActive={tabActive === "list"}>
                    <Category>
                        <TitleText {...subTitleStyle}>쿠폰 등록</TitleText>
                        <InputWrap>
                            <CouponInput
                                placeholder="쿠폰 번호를 입력해주세요."
                                type="text"
                                name={"coupon"}
                                value={coupon}
                                onChange={(e) => setCoupon(e.target.value)}
                                onKeyPress={(e) => {
                                    genericEnterKeyDown(e, () => {
                                        if (coupon) {
                                            couponRegister.mutate(
                                                { number: coupon },
                                                {
                                                    onSuccess: () => {
                                                        setCoupon("");
                                                    },
                                                },
                                            );
                                        }
                                    });
                                }}
                            />
                            <Button
                                onlyText
                                margin={"0 0 0 8px"}
                                width={"56px"}
                                height={"32px"}
                                fontColor={"var(--color-White)"}
                                bgColor={"var(--color-Button2)"}
                                hoverBgColor={"var(--color-ButtonHover2)"}
                                buttonText={"등록"}
                                disabled={!coupon}
                                onClick={(e) => {
                                    couponRegister.mutate(
                                        { number: coupon },
                                        {
                                            onSuccess: () => {
                                                setCoupon("");
                                            },
                                        },
                                    );
                                }}
                            />
                        </InputWrap>
                    </Category>
                    <Category flexDirection={"column"} alignItems={"flex-start"} margin={"0"} height={"auto"}>
                        <TitleText {...subTitleStyle}>내 쿠폰</TitleText>
                        <TableWrap>
                            <Table>
                                <tbody>
                                    <Tr>
                                        <Th width={"200px"}>
                                            <GeneralText {...thStyle}>쿠폰 이름</GeneralText>
                                        </Th>
                                        <Th>
                                            <GeneralText {...thStyle}>쿠폰번호</GeneralText>
                                        </Th>
                                        <Th>
                                            <GeneralText {...thStyle}>사용 가능 기한</GeneralText>
                                        </Th>
                                        <Th width={"80px"}>
                                            <GeneralText {...thStyle}>사용</GeneralText>
                                        </Th>
                                    </Tr>
                                    {unUsedCouponList.length === 0 ? (
                                        <Tr style={{ height: "80px" }}>
                                            <Td colSpan="4">
                                                <GeneralText {...blankStyle}>사용 가능한 쿠폰이 없습니다.</GeneralText>
                                            </Td>
                                        </Tr>
                                    ) : (
                                        unUsedCouponList.map((coupon, index) => (
                                            <Tr key={index}>
                                                <Td textAlign="center;">
                                                    <GeneralText {...tdStyle}>
                                                        <DateText>{coupon.name}</DateText>
                                                    </GeneralText>
                                                </Td>
                                                <Td>
                                                    <GeneralText {...tdStyle}>{coupon.number}</GeneralText>
                                                </Td>
                                                <Td>
                                                    <GeneralText {...tdStyle}>
                                                        <DateText>
                                                            {coupon.expired_at ? coupon.expired_at : "기한 없음"}
                                                        </DateText>
                                                    </GeneralText>
                                                </Td>
                                                <Td>
                                                    <DetailRow>
                                                        <Button
                                                            onlyText
                                                            width={"40px"}
                                                            height={"24px"}
                                                            fontColor={"var(--color-White)"}
                                                            fontSize={"xsmall"}
                                                            size={"xsmall"}
                                                            buttonText={"사용"}
                                                            bgColor={"var(--color-Button2)"}
                                                            hoverBgColor={"var(--color-ButtonHover2)"}
                                                            onClick={(e) => {
                                                                if (isGroupPlanUser(user)) {
                                                                    Alert(
                                                                        "warn",
                                                                        "쿠폰 사용",
                                                                        "단체 플랜 구독 중에는 사용할 수 없는 쿠폰입니다.",
                                                                    );
                                                                    return;
                                                                }
                                                                couponUse.mutate({ number: coupon.number });
                                                            }}
                                                        />
                                                    </DetailRow>
                                                </Td>
                                            </Tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </TableWrap>
                    </Category>
                </ListView>
                <HistoryView isActive={tabActive === "history"}>
                    <Category margin={"0"} height={"auto"}>
                        <TableWrap>
                            <Table>
                                <tbody>
                                    <Tr>
                                        <Th>
                                            <GeneralText {...thStyle}>쿠폰 이름</GeneralText>
                                        </Th>
                                        <Th>
                                            <GeneralText {...thStyle}>쿠폰번호</GeneralText>
                                        </Th>
                                        <Th width={"240px"}>
                                            <GeneralText {...thStyle}>사용</GeneralText>
                                        </Th>
                                    </Tr>
                                    {usedCouponList.length === 0 ? (
                                        <Tr style={{ height: "80px" }}>
                                            <Td colSpan="3">
                                                <GeneralText {...blankStyle}>쿠폰 사용 내역이 없습니다.</GeneralText>
                                            </Td>
                                        </Tr>
                                    ) : (
                                        usedCouponList.map((coupon, index) => (
                                            <Tr key={index}>
                                                <Td textAlign="center;">
                                                    <GeneralText {...tdStyle}>
                                                        <DateText>{coupon.name}</DateText>
                                                    </GeneralText>
                                                </Td>
                                                <Td>
                                                    <GeneralText {...tdStyle}>{coupon.number}</GeneralText>
                                                </Td>
                                                <Td>
                                                    <GeneralText {...tdStyle}>
                                                        <DateText>{coupon.used_at}</DateText>
                                                    </GeneralText>
                                                </Td>
                                            </Tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </TableWrap>
                    </Category>
                </HistoryView>
            </SectionContainer>

            <DivideSection />
            {/* 결제 내역 */}
            <SectionContainer padding={"0 0 160px 0"}>
                <Category margin={"0"}>
                    <TitleText withBarType {...barTitleStyle}>
                        결제 내역
                    </TitleText>
                </Category>
                <ContentsWrapper>
                    <ContentsInner>
                        <TableWrap>
                            <Table>
                                <tbody>
                                    <Tr>
                                        <Th width="160px">
                                            <GeneralText {...thStyle}>일시</GeneralText>
                                        </Th>
                                        <Th textAlign="left">
                                            <GeneralText {...thStyle}>상세</GeneralText>
                                        </Th>
                                        <Th width="80px">
                                            <GeneralText {...thStyle}>영수증</GeneralText>
                                        </Th>
                                        <Th width="120px">
                                            <GeneralText {...thStyle}>금액</GeneralText>
                                        </Th>
                                    </Tr>
                                    {paymentHistory.length === 0 ? (
                                        <Tr style={{ height: "80px" }}>
                                            <Td colSpan="3">
                                                <GeneralText {...blankStyle}>결제 내역이 없습니다.</GeneralText>
                                            </Td>
                                        </Tr>
                                    ) : (
                                        paymentHistory.map((history, index) => (
                                            <Tr key={index}>
                                                <Td>
                                                    <GeneralText {...tdStyle}>
                                                        <DateText>{history.created_at}</DateText>
                                                    </GeneralText>
                                                </Td>
                                                <Td textAlign="left">
                                                    <GeneralText
                                                        margin={"0 4px 0 0"}
                                                        size={"small"}
                                                        color={"var(--color-Black)"}
                                                    >
                                                        {history.description}
                                                    </GeneralText>
                                                </Td>
                                                <Td>
                                                    <DetailRow>
                                                        {history.status === "COMPLETED" && (
                                                            <Button
                                                                onlyText
                                                                width={"40px"}
                                                                height={"24px"}
                                                                fontColor={"var(--color-White)"}
                                                                size={"xsmall"}
                                                                buttonText={"영수증"}
                                                                bgColor={"var(--color-Button2)"}
                                                                hoverBgColor={"var(--color-ButtonHover2)"}
                                                                onClick={(e) => {
                                                                    setPaymentBillingHistory(history);
                                                                    setShowPaymentHistoryModal(true);
                                                                }}
                                                            />
                                                        )}
                                                    </DetailRow>
                                                </Td>
                                                <Td textAlign="right" padding="0 24px 0 0">
                                                    <GeneralText {...tdStyle}>
                                                        <Amount>{history.amount}</Amount>원
                                                    </GeneralText>
                                                </Td>
                                            </Tr>
                                        ))
                                    )}
                                </tbody>
                            </Table>
                        </TableWrap>
                    </ContentsInner>
                </ContentsWrapper>
            </SectionContainer>
            <div
                style={{
                    display: "none",
                    overFlow: "scroll",
                    position: "fixed",
                    zIndex: 10000,
                    top: 0,
                    left: 0,
                    width: "100vw",
                    height: "100vh",
                    padding: "120px 0",
                    background: "rgba(0,0,0,.48)",
                    boxSizing: "border-box",
                }}
                id={"iamport-modal"}
            ></div>
            {showPlanGroupInfoModal && (
                <AbstractModal
                    modalTitle="구독하기"
                    width={500}
                    height={640}
                    exitModal={(e) => setShowPlanGroupInfoModal(!showPlanGroupInfoModal)}
                >
                    <PlanGroupInfoModal
                        planGroupInfo={paymentPlanGroup}
                        subscribeAct={(e) => subscribeGroupPlan()}
                        exitModal={(e) => setShowPlanGroupInfoModal(!showPlanGroupInfoModal)}
                    />
                </AbstractModal>
            )}
            {showPlanGroupMemberModal && (
                <AbstractModal
                    modalTitle="구독 구성원"
                    width={500}
                    height={640}
                    exitModal={(e) => setShowPlanGroupMemberModal(!showPlanGroupMemberModal)}
                >
                    <PlanGroupMemberModal
                        paymentPlanGroup={paymentPlanGroup}
                        paymentPlanGroupUser={paymentPlanGroupUser}
                    />
                </AbstractModal>
            )}
            {showPaymentHistoryModal && (
                <AbstractModal
                    modalTitle=""
                    width={500}
                    height={640}
                    exitModal={(e) => setShowPaymentHistoryModal(!showPaymentHistoryModal)}
                >
                    <PaymentBillingHistoryModal id={paymentBillingHistory.id} user={user} />
                </AbstractModal>
            )}
        </Container>
    );
}

export default TabPaymentView;
