import React, { useEffect, useRef, useState } from "react";
import { useIsFetching, useIsMutating } from "react-query";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";

import styled, { css } from "styled-components";

import AiCursor from "components/atoms/AiCursor";
import HtmlHead from "components/atoms/HtmlHead";
import Icon from "components/atoms/Icon";
import Textarea from "components/atoms/Textarea";
import Tooltip from "components/atoms/Tooltip";
import Alert from "components/atoms/alert/Alert";
import Confirm from "components/atoms/alert/Confirm";
import Button from "components/atoms/button/Button";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import StyledText from "components/atoms/text/StyledText";
import TitleText from "components/atoms/text/TitleText";
import useToast from "components/atoms/toast/useToast";

import ReferenceDetailRelatedPaperItem from "components/organisms/reference/ReferenceDetailRelatedPaperItem";
import ReferenceDetailTitleInput from "components/organisms/reference/ReferenceDetailTitleInput";
import ReferenceResearchList from "components/organisms/reference/ReferenceResearchList";
import ReferenceTagList from "components/organisms/reference/ReferenceTagList";
import TabReferenceBibInfo from "components/organisms/reference/TabReferenceBibInfo";
import AbstractModal from "components/page/modal/AbstractModal";
import ReferenceBibInfoLoadModal from "components/page/modal/ReferenceBibInfoLoadModal";
import ReferenceCitationEditModal from "components/page/modal/ReferenceCitationEditModal";
import ReferenceCitationStyleModal from "components/page/modal/ReferenceCitationStyleModal";
import ReferenceMetadataAnalyzeModal from "components/page/modal/ReferenceMetadataAnalyzeModal";
import ReferenceTagEditModal from "components/page/modal/ReferenceTagEditModal";
import LoginUserLayout from "components/templates/LoginUserLayout";

import { useBibliography, useBibliographyDelete, useBibliographyUpdate } from "hooks/queries/useBibliographies";
import { useUser } from "hooks/queries/useUser";
import { useSessionPageHistory } from "hooks/useSessionPageHistory";

import Constants from "utils/constants";
import { getDateFromString, getTimeFromString } from "utils/dateUtil";
import { useDevice } from "utils/device";
import { apiUrl } from "utils/urls";
import { wsUrl } from "utils/urls";
import { genericEnterKeyDown } from "utils/validation";

import axios from "axios";
import { saveAs } from "file-saver";

const WidthLimit = styled.div`
    position: relative;
    display: flex;
    justify-content: ${(props) => props.justifyContent};
    align-items: ${(props) => props.alignItems};
    width: 100%;
    max-width: 1080px;
`;

const Header = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    height: 48px;
    max-height: 48px;
    background-color: var(--color-White);
`;

const HeaderLeftSec = styled.div`
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    max-width: calc(100% - 60px);
`;

const HeaderRightSec = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 52px;
    margin: 0 0 0 8px;
`;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    margin: 48px 0 0 0;
    padding: 0 48px;
    width: 100%;
    height: calc(100vh - 104px);
`;

const ContentsWrapper = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    justify-content: space-between;
`;

const ContentsLeftSide = styled.div`
    width: 100%;
    max-width: calc(100% - 224px);
`;

const ContentsRightSide = styled.div`
    width: 200px;
    max-width: 200px;
    min-height: 520px;
`;

const BibInfoWrap = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    padding: 16px 4px 0px 4px;
    max-width: calc(100% - 4px);
`;

const BibInfoText = styled(StyledText)`
    display: inline-block;
    margin: 0 8px 0 0;
    font-size: 1.4rem;
    color: var(--color-Grey1);
`;

const BibBtnWrap = styled.div`
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 80px;
    margin: 0;
`;

const SummaryWrap = styled.div`
    flex: 1;
    margin: 16px 0 40px 0;
    min-height: 32px;
`;

const TabButtonWrap = styled.div`
    position: sticky;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    background-color: var(--color-White);
    z-index: 1;
`;

const TabButton = styled.button`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    height: 40px;
    background-color: var(--color-White);
    border: transparent;
    outline: none;

    &:hover {
        p {
            color: var(--color-Key);
        }
    }
`;

const TabButtonText = styled(StyledText)`
    font-size: 1.4rem;
    font-weight: 500;
    color: ${(props) => (props.isActive ? "var(--color-Key)" : "var(--color-DisabledText)")};
`;

const TabLine = styled.span`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${(props) => (props.isActive ? "var(--color-Key)" : "var(--color-DisabledInput)")};
`;

const TabConContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 24px 0 80px 0;
    width: 100%;

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 24px 0 160px 0;
        `};
`;

const TabBiblioInformationWrap = styled.div`
    flex: 1;
    min-width: 100%;
`;

const TabSummaryWrap = styled.div`
    flex: 1;
    min-width: 100%;
`;

const SummarySection = styled.div`
    margin: 0 0 24px 0;

    &:last-child {
        margin: 0;
    }
`;

const SummaryTitleSec = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
`;

const SummaryTitleText = styled(StyledText)`
    font-size: 1.6rem;
    font-weight: 500;
    margin: 0 8px 0 0;
`;

const SummaryTextWrap = styled.div`
    position: relative;
    z-index: 0;
`;

const SummaryText = styled(StyledText)`
    font-size: 1.4rem;
    white-space: pre-wrap;
    text-align: justify;
    word-break: break-all;
    list-style: disc;
`;

const PayWallWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    overflow: hidden;
`;

const PayWallGradientTop = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 16%;
    background: linear-gradient(var(--color-White), transparent);
`;

const PayWallGradientBottom = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 16%;
    background: linear-gradient(transparent, var(--color-White));
`;

const PayWall = styled.div`
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.9);
    filter: blur(6px);
    -webkit-filter: blur(6px);
`;

const PayWallMsgWrap = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
`;

const TabRelpaperWrap = styled.div`
    flex: 1;
    width: 100%;
`;

const RelPaperSection = styled.div``;

const RelPapTitRow = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 16px 0;
`;

const RelPaperTitleText = styled(StyledText)`
    font-size: 1.6rem;
    font-weight: 500;
`;

const RelPapNumCircleText = styled(StyledText)`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 8px;
    width: 16px;
    height: 16px;
    background-color: var(--color-Key);
    border-radius: 8px;

    font-size: 1.1rem;
    font-weight: 600;
    color: var(--color-White);
`;

const RelPaperTextWrap = styled.div`
    position: relative;
`;

const PaperUl = styled.ul`
    padding: 0 0 0 4px;
`;

const PaperLi = styled.li`
    list-style: none;
    margin: 0 0 8px 0;

    &:last-child {
        margin: 0;
    }
`;

const RelPapBlankView = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    background-color: var(--color-White);
    border: solid 1px #e9eff2;
    border-radius: 4px;
`;

const RelPapBlankViewText = styled(StyledText)`
    font-size: 1.4rem;
    color: var(--color-Grey1);
`;

const SubContentsBox = styled.div`
    position: sticky;
    top: 24px;
    padding: 12px;
    min-width: 200px;
    max-width: 200px;
    height: fit-content;
    background-color: var(--color-BaseBlue);
    border: solid 1px var(--color-Outline);
    border-radius: 4px;
    z-index: 1;
`;

const PDFWrap = styled.div`
    padding: 0 0 12px 0;
    border-bottom: solid 1px #d8dee3;
`;

const PDFTitleText = styled(StyledText)`
    font-size: 1.4rem;
    margin: 0 0 8px 0;
    padding: 0 0 0 8px;
    border-left: solid 2px var(--color-TitleBar);
`;

const MemoTitleText = styled(StyledText)`
    margin: 0 0 8px 0;

    font-size: 1.4rem;
    font-weight: 500;

    padding: 0 0 0 8px;
    border-left: solid 2px var(--color-TitleBar);
`;

const MemoTextarea = styled.textarea`
    padding: 4px 6px;
    width: 100%;
    min-height: 80px;
    max-height: calc(100vh - 560px);
    font-size: 1.2rem;
    font-weight: 400;
    color: var(--color-SubBlack);
    background-color: var(--color-White);
    border: solid 1px #ecf2f4;
    border-radius: 2px;
    resize: none;
    outline: none;

    &::placeholder {
        color: var(--color-Grey2);
    }
`;

const PDFInputWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 8px 0;
    padding: 2px;
    height: 24px;
    background-color: var(--color-White);
    border-radius: 2px;
    border: solid 1px #ecf2f4;
`;

const PDFInput = styled.input`
    margin: 0 0 0 4px;
    flex: 1;
    width: calc(100% - 46px);
    height: 16px;
    font-size: 1.4rem;
    background-color: transparent;
    border: transparent;
    outline: none;
    cursor: pointer;

    &.disabled {
        color: var(--color-Grey1);
        background-color: var(--color-White) !important;
    }
`;

const PDFButtonWrap = styled.div`
    display: flex;
    align-items: center;
    margin: 0 0 0 4px;
    width: auto;
`;

const PdfButton = styled(Button)`
    height: 24px;
    font-size: 1.4rem;
    color: var(--color-White);
    background-color: var(--color-Button2);

    &:hover {
        background-color: var(--color-ButtonHover2);
    }
`;

const ResearchTagWrap = styled.div`
    padding: 12px 0;
    border-bottom: solid 1px #d8dee3;
`;

const MemoWrap = styled.div`
    padding: 12px 0;
    border-bottom: solid 1px #d8dee3;
`;

const DateWrap = styled.div`
    padding: 12px 0 0 0;
`;

const DateWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 4px 0;
`;

const LinkButton = styled(Button)`
    height: 18px;
    padding: 0 4px;
    border-radius: 4px;
    font-size: 1.2rem;
    background-color: var(--color-Button3);

    &:hover {
        background-color: var(--color-ButtonHover3);
    }
`;

const DateText = styled(StyledText)`
    font-size: 1.2rem;
    color: var(--color-Grey1);
`;

const DOIWrap = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;

    a {
        margin: 0 4px 0 0;

        &:last-child {
            margin: 0;
        }
    }
`;

function ReferenceDetailPage(props) {
    const { uuid } = useParams();
    const uuidRef = useRef(uuid);
    const location = useLocation();
    const device = useDevice();
    const navigate = useNavigate();

    const [user, setUser] = useState({});

    const [bibliography, setBibliography] = useState({
        id: "",
        citation_key: "",
        display_name: "",
        file: null,
        bibtex: {
            title: "",
            author: [],
            year: "",
            how_published: "",
        },
        researches: [],
    });

    const [tabActive, setTabActive] = useState();

    const [fileStatus, setFileStatus] = useState(null);

    const [showTagEditModal, setShowTagEditModal] = useState(false);
    const [showBibInfoLoadModal, setShowBibInfoLoadModal] = useState(false);
    const [showCitationEditModal, setShowCitationEditModal] = useState(false);
    const [showCitationStyleModal, setShowCitationStyleModal] = useState(false);
    const [showMetadataAnalyzeModal, setShowMetadataAnalyzeModal] = useState(false);
    const [queueMetadataAnalyzeModal, setQueueMetadataAnalyzeModal] = useState(false);

    const { setToast } = useToast();
    const { setLoading } = useLoading();

    const userQuery = useUser();
    const bibliographyQuery = useBibliography({ uuid: uuid });
    const updateBibliographyMutation = useBibliographyUpdate();
    const deleteBibliographyMutation = useBibliographyDelete();

    const pdfInputRef = useRef(null);

    const abstractRef = useRef(null);

    const isFetching = useIsFetching();
    const isMutating = useIsMutating();

    useEffect(() => {
        if (isFetching > 0 || isMutating > 0) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [isFetching, isMutating]);

    // 초기화
    useEffect(() => {
        return () => {
            bibliographyQuery.remove();
        };
    }, []);

    // 상세에서 상세 페이지로 이동한 경우
    useEffect(() => {
        if (uuid !== uuidRef.current) {
            bibliographyQuery.refetch();
            uuidRef.current = uuid;
        }
    }, [uuid]);

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
    }, [userQuery.data]);

    useEffect(() => {
        if (bibliographyQuery.data) {
            if (!bibliography.id) {
                // 파일이 있으면 원문분석 탭, 파일이 없으면 서지정보 탭으로 이동
                if (bibliographyQuery.data.file_id) {
                    setTabActive("summary");
                } else {
                    setTabActive("bibInfo");
                }
            }
            setBibliography(bibliographyQuery.data);

            // 기존 분석한 요약, 비평 콘텐츠가 있다면 표시
            if (bibliographyQuery.data.summary) {
                let summary_obj = bibliographyQuery.data.summary;
                if (summary_obj.summary && summary_obj.summary !== "") {
                    setSummaryText(summary_obj.summary);
                }
                if (summary_obj.review && summary_obj.review !== "") {
                    setReviewText(summary_obj.review);
                }
            }
        }
    }, [bibliographyQuery.data]);

    // mutation
    const updateBibliography = (field = undefined) => {
        if (bibliography.id) {
            if (field) {
                let data = {
                    id: bibliography.id,
                    [field]: bibliography[field],
                };
                updateBibliographyMutation.mutate(data);
            }
        }
    };

    const updateAbstract = () => {
        if (bibliography.id) {
            let data = {
                id: bibliography.id,
                bib_json: bibliography.bib_json,
            };
            updateBibliographyMutation.mutate(data);
        }
    };

    // input change handler
    const inputChangeHandler = (event) => {
        const { name, value } = event.target;
        setBibliography((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const bibJsonChangeHandler = (event) => {
        const { name, value } = event.target;
        setBibliography((prevState) => ({
            ...prevState,
            bib_json: {
                ...prevState.bib_json,
                [name]: value,
            },
        }));
    };

    // file input change handler
    const fileChangeHandler = (e) => {
        if (bibliography.file) {
            axios.head(bibliography.file).then((res) => {
                if (
                    user.plan_id === Constants.FREE_PLAN &&
                    user.file_total_size - res.headers["content-length"] + e.target.files[0].size > 200 * Constants.MB
                ) {
                    Alert("warn", "에러", "파일 용량이 초과되었습니다.");
                    return;
                }
            });
        } else {
            if (
                user.plan_id === Constants.FREE_PLAN &&
                user.file_total_size + e.target.files[0].size > 200 * Constants.MB
            ) {
                Alert("warn", "에러", "파일 용량이 초과되었습니다.");
                return;
            }
        }
        // 50MB 이상의 파일은 업로드 불가
        if (e.target.files[0].size > 50 * Constants.MB) {
            Alert("warn", "에러", "50MB 이상의 파일은 업로드할 수 없습니다.");
            return;
        }
        const { files } = e.target;
        if (files.length > 0) {
            let upload_data = { file: files[0], reference_id: bibliography.id };
            uploadFile(upload_data);
        }
    };

    const uploadFile = (upload_data) => {
        setLoading(true, "PDF 파일을 업로드 중입니다.");
        setFileStatus("loading");
        axios
            .post(apiUrl("bibliography") + "/file", upload_data, {
                headers: { "Content-Type": "multipart/form-data" },
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    setLoading(true, `PDF 파일을 업로드 중입니다. (${percentCompleted}%)`);
                    if (percentCompleted === 100) {
                        setLoading(true, "PDF 파일을 저장 중입니다.");
                    }
                },
            })
            .then((res) => {
                setFileStatus(null);
                setToast("PDF 파일이 업로드되었습니다.", "info");

                setLoading(false);
                bibliographyQuery.refetch();
                setShowMetadataAnalyzeModal(true);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
                setFileStatus(null);
                Alert("warn", "에러", error.response.data.file);
            });
    };

    const handleDownloadFile = (e) => {
        e.stopPropagation();
        saveAs(bibliography.file, `${decodeURI(bibliography.file?.split("/").pop())}`);
    };

    const handleDeleteFile = (e) => {
        e.stopPropagation();
        Confirm(
            "warn",
            "참고문헌 PDF 삭제",
            "이 참고문헌의 PDF 파일을 삭제하시겠습니까? PDF파일을 삭제하시면 더이상 리뷰를 할 수 없습니다. ",
            "삭제",
            (e) => {
                axios
                    .delete(apiUrl("bibliography") + "/file/reference", {
                        data: { reference_id: bibliography.id },
                    })
                    .then((res) => {
                        setBibliography({
                            ...bibliography,
                            file: null,
                            file_id: null,
                            file_uuid: null,
                        });
                        setToast("PDF 파일이 삭제되었습니다.", "info");
                        setFileStatus(null);
                        setAnalysisErrorMessage("");
                    })
                    .catch((error) => {
                        if (bibliography.file) {
                            setFileStatus(null);
                        }
                        Alert(
                            "warn",
                            "에러",
                            "참고문헌 PDF 파일 삭제 중 오류가 발생하였습니다.\n잠시후 다시 시도해주세요.",
                        );
                    });
            },
        );
    };

    const setAnalysisErrorMessage = (message) => {
        let errorMessages = document.querySelectorAll(`.server-error-message[name='file']`);
        errorMessages.forEach((errorMessage) => {
            errorMessage.innerText = message;
        });
    };

    // clipboard 복사
    const handleCopyButtonClick = () => {
        navigator.clipboard
            .writeText(bibliography.styled_text)
            .then(() => {
                setToast("서지정보가 복사되었습니다", "info");
            })
            .catch((error) => {
                console.error("복사 실패:", error);
            });
    };

    // 북마크
    const handleBookmarkButtonClick = () => {
        updateBibliographyMutation.mutate({
            id: bibliography.id,
            is_favorite: !bibliography.is_favorite,
        });
    };

    // 참고 문헌 삭제
    const handleDeleteButtonClick = () => {
        let message = "참고문헌을 삭제하시겠습니까?";
        if (bibliography.citation_count > 0) {
            message = "리서치에 인용된 참고문헌입니다. 삭제하시겠습니까?";
        }
        Confirm("warn", "참고문헌 삭제", message, "삭제", (e) => {
            deleteBibliographyMutation.mutate({
                ids: [bibliography.id],
            });
            navigate(-1);
        });
    };

    // 메모 텍스트에어리어
    const textareaRef = useRef();

    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
    }, [bibliography?.memo]);

    useEffect(() => {
        if (location.state?.file && bibliography?.id) {
            if (!bibliography.file) {
                // 참고문헌 생성의 경우
                let upload_data;
                if (typeof location.state.file === "string") {
                    upload_data = { file_url: location.state.file, reference_id: bibliography.id };
                } else {
                    // PDF 파일로 추가한 경우
                    upload_data = { file: location.state.file, reference_id: bibliography.id };
                }
                uploadFile(upload_data);
            }
        }
    }, [location.state?.file, bibliography?.id]);

    //region: 웹소켓 요약, 비평 처리
    const [summaryText, setSummaryText] = useState("");
    const [showSummaryStartButton, setShowSummaryStartButton] = useState(true);
    const [showSummaryStopButton, setShowSummaryStopButton] = useState(false);
    const [showSummaryAiCursor, setShowSummaryAiCursor] = useState(false);
    const [reviewText, setReviewText] = useState("");
    const [showReviewStartButton, setShowReviewStartButton] = useState(true);
    const [showReviewStopButton, setShowReviewStopButton] = useState(false);
    const [showReviewAiCursor, setShowReviewAiCursor] = useState(false);

    const wsSummaryRef = useRef(null);
    const wsReviewRef = useRef(null);

    // 웹소켓 연결 종료
    useEffect(() => {
        return () => {
            if (wsSummaryRef.current) {
                wsSummaryRef.current.close();
            }
            if (wsReviewRef.current) {
                wsReviewRef.current.close();
            }
        };
    }, []);

    const startSummaryAndReview = () => {
        if (bibliography.id && bibliography.file) {
            startAnalysis("summary");
            setTimeout(() => startAnalysis("review"), 1000); // 서버에 약간 여유를 주기 위해 호출 사이에 지연 시간 추가
        }
    };

    const startAnalysis = (analysis_type) => {
        if (analysis_type === "summary") {
            startSummary(
                analysis_type,
                wsSummaryRef,
                setShowSummaryStartButton,
                setShowSummaryStopButton,
                setShowSummaryAiCursor,
                setSummaryText,
            );
        } else if (analysis_type === "review") {
            startSummary(
                analysis_type,
                wsReviewRef,
                setShowReviewStartButton,
                setShowReviewStopButton,
                setShowReviewAiCursor,
                setReviewText,
            );
        } else {
            return;
        }
    };

    const stopAnalysis = (analysis_type) => {
        let ws = analysis_type === "summary" ? wsSummaryRef : wsReviewRef;
        ws.current.send(
            JSON.stringify({
                action: "stop",
            }),
        );
    };

    const startSummary = (analysis_type, ws, setShowStartButton, setShowStopButton, setShowAiCursor, setText) => {
        if (bibliography.id && bibliography.file && bibliography.file_id) {
            if (ws.current === null) {
                ws.current = new WebSocket(wsUrl("referenceAnalysis"));

                ws.current.onopen = () => {
                    setShowStartButton(false);
                    setShowAiCursor(true);
                    setText("PDF 파일을 분석 중입니다.");
                    if (ws.current) {
                        ws.current.send(
                            JSON.stringify({
                                action: "analyze",
                                analysis_type: analysis_type,
                                reference_file_id: bibliography.file_id,
                            }),
                        );
                    }
                };

                ws.current.onmessage = (event) => {
                    const data = JSON.parse(event.data);

                    if (data.content === "<START>") {
                        setText("");
                        setShowStopButton(true);
                    } else if (data.content === "<END>") {
                        ws.current.close();
                        setShowStopButton(false);
                    } else {
                        setText((prev) => {
                            return prev + data.content;
                        });
                    }
                };

                ws.current.onerror = (error) => {
                    console.log(error);
                };

                ws.current.onclose = (event) => {
                    if (event.code !== 1000) {
                        // 정상 종료가 아닌 경우
                        console.log(event);
                        Alert("warn", "에러", "오류가 발생했습니다.\n잠시 후 새로고침 해주세요.");
                    }
                    ws.current = null;
                    setShowStartButton(true);
                    setShowAiCursor(false);
                };
            }
        }
    };

    const renderContent = (show_ai_cursor, text) => {
        if (!bibliography?.file) {
            return <SummaryText>PDF 파일이 없습니다.</SummaryText>;
        }

        if (user.plan_id === Constants.FREE_PLAN) {
            return (
                <>
                    <PayWallWrap>
                        <PayWall>
                            <SummaryText>{text.replaceAll(/\S/gm, "*")}</SummaryText>
                        </PayWall>
                        <PayWallGradientTop />
                        <PayWallGradientBottom />
                    </PayWallWrap>
                    <PayWallMsgWrap>
                        <GeneralText size={"small"}>유료 요금제를 구독 후 내용을 확인해주세요.</GeneralText>
                    </PayWallMsgWrap>
                </>
            );
        } else {
            return (
                <SummaryText>
                    {text}
                    {show_ai_cursor && <AiCursor />}
                </SummaryText>
            );
        }
    };

    //region: 연관 논문 분석
    const [relatedPapers, setRelatedPapers] = useState({});

    useEffect(() => {
        if (tabActive === "relatedPapers") {
            setLoading(true, "연관논문을 불러오는 중입니다.");
            axios
                .get(apiUrl("paperRelatedPapers"), {
                    params: {
                        title: bibliography.bib_json.title,
                        doi: bibliography.bib_json.doi,
                    },
                })
                .then((res) => {
                    setRelatedPapers(res.data);
                    setLoading(false);
                })
                .catch((error) => {
                    Alert("warn", "에러", "연관논문을 불러오지 못했습니다. 잠시 후 시도해주세요.");
                    console.log(error);
                    setLoading(false);
                });
        } else {
            setRelatedPapers({});
        }
    }, [tabActive]);

    const [searchParams, setSearchParams] = useState();
    const { prevPage } = useSessionPageHistory();

    const loadSessionCache = () => {
        const sessionCacheJson = sessionStorage.getItem("referenceList");
        if (sessionCacheJson) {
            const sessionCacheObj = JSON.parse(sessionCacheJson);
            setSearchParams(sessionCacheObj.searchParams);
        }
    };

    useEffect(() => {
        if (prevPage && prevPage.startsWith("/reference")) {
            loadSessionCache();
        }
    }, [prevPage]);

    //region: 마크업 시작
    return (
        <LoginUserLayout searchParams={searchParams}>
            <HtmlHead title={bibliography?.display_name} />

            {/* 헤더 : 스크롤 포지션 고정 */}
            <Header bibliography={bibliography}>
                <WidthLimit device={device} justifyContent={"center"} alignItems={"center"}>
                    {/* 참고문헌 표시 이름 */}
                    <HeaderLeftSec>
                        <Tooltip message={"뒤로"}>
                            <Button
                                onlyIcon
                                bgColor={"var(--color-Button4)"}
                                hoverBgColor={"var(--color-ButtonHover4)"}
                                fontColor={"var(--color-Black)"}
                                onClick={() => {
                                    navigate(-1);
                                }}
                                disabled={window.history.length === 1}
                            >
                                <Icon name="arrowLeft" size="12px" />
                            </Button>
                        </Tooltip>
                        <ReferenceDetailTitleInput
                            name={"display_name"}
                            placeholder={"참고문헌 이름을 입력해주세요."}
                            biblioText={bibliography?.display_name}
                            allowEmpty={false}
                            onChange={inputChangeHandler}
                            saveAct={(e) => updateBibliography("display_name")}
                        />
                    </HeaderLeftSec>
                    <HeaderRightSec>
                        {/* 북마크 버튼 */}
                        <Tooltip message={"즐겨찾기"}>
                            <Button
                                onlyIcon
                                border={
                                    bibliography?.is_favorite
                                        ? "solid 2px var(--color-Key)"
                                        : "solid 2px var(--color-DisabledButton)"
                                }
                                bgColor={"var(--color-White)"}
                                hoverBgColor={"none"}
                                onClick={handleBookmarkButtonClick}
                            >
                                <Icon
                                    name={"bookmark"}
                                    size={"12"}
                                    color={
                                        bibliography?.is_favorite ? "var(--color-Key)" : "var(--color-DisabledButton)"
                                    }
                                />
                            </Button>
                        </Tooltip>

                        {/* 참고문헌 삭제 버튼 */}
                        <Tooltip message={"참고문헌 삭제"}>
                            <Button
                                onlyIcon
                                bgColor={"var(--color-Button2)"}
                                hoverBgColor={"var(--color-ButtonHover2)"}
                                onClick={handleDeleteButtonClick}
                            >
                                <Icon name={"delete"} size={"12"} color={"var(--color-White)"} />
                            </Button>
                        </Tooltip>
                    </HeaderRightSec>
                </WidthLimit>
            </Header>

            {/* 바디 : 스크롤 영역 (TabButton, SubContentsBox 제외) */}
            <Body>
                <WidthLimit device={device} justifyContent={"space-between"} alignItems={"flex-start"}>
                    <ContentsWrapper>
                        <ContentsLeftSide>
                            {/* 서지정보 */}
                            <BibInfoWrap>
                                <BibInfoText>{bibliography?.styled_text}</BibInfoText>
                                <BibBtnWrap>
                                    <Tooltip message={"클립보드 복사"}>
                                        <Button
                                            onlyIcon
                                            bgColor={"var(--color-White)"}
                                            hoverBgColor={"var(--color-ButtonHover4)"}
                                            onClick={handleCopyButtonClick}
                                        >
                                            <Icon name={"copy"} size={"12"} color={"var(--color-SubBlack)"} />
                                        </Button>
                                    </Tooltip>
                                    <Tooltip message={"인용 스타일"}>
                                        <Button
                                            onlyIcon
                                            bgColor={"var(--color-White)"}
                                            hoverBgColor={"var(--color-ButtonHover4)"}
                                            onClick={(e) => setShowCitationStyleModal(!showCitationStyleModal)}
                                        >
                                            <Icon name={"list"} size={"12"} color={"var(--color-SubBlack)"} />
                                        </Button>
                                    </Tooltip>
                                </BibBtnWrap>
                            </BibInfoWrap>

                            {/* 초록 */}
                            <SummaryWrap>
                                <Textarea
                                    ref={abstractRef}
                                    placeholder="작성된 초록이 없습니다."
                                    name={"abstract"}
                                    value={bibliography?.bib_json?.abstract}
                                    onChange={bibJsonChangeHandler}
                                    saveAct={(e) => updateAbstract()}
                                    padding={"2px"}
                                />
                            </SummaryWrap>

                            {/* 탭 버튼 */}
                            <TabButtonWrap>
                                <TabButton onClick={() => setTabActive("summary")}>
                                    <TabButtonText isActive={tabActive === "summary"}>원문분석</TabButtonText>
                                    <TabLine isActive={tabActive === "summary"} />
                                </TabButton>
                                <TabButton onClick={() => setTabActive("relatedPapers")}>
                                    <TabButtonText isActive={tabActive === "relatedPapers"}>연관논문</TabButtonText>
                                    <TabLine isActive={tabActive === "relatedPapers"} />
                                </TabButton>
                                <TabButton onClick={() => setTabActive("bibInfo")}>
                                    <TabButtonText isActive={tabActive === "bibInfo"}>서지정보</TabButtonText>
                                    <TabLine isActive={tabActive === "bibInfo"} />
                                </TabButton>
                            </TabButtonWrap>

                            {/* 요약 & 연관논문 탭 섹션 */}
                            <TabConContainer device={device}>
                                {/* 요약 탭의 컨텐츠 영역 */}
                                {tabActive === "summary" && (
                                    <TabSummaryWrap>
                                        <SummarySection>
                                            <SummaryTitleSec>
                                                <SummaryTitleText>AI 요약</SummaryTitleText>
                                                {showSummaryStartButton &&
                                                    bibliography?.file_id &&
                                                    user.plan_id !== Constants.FREE_PLAN && (
                                                        <Tooltip message={"새로쓰기"}>
                                                            <Button
                                                                onlyIcon
                                                                bgColor={"transparent"}
                                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                                onClick={(e) => {
                                                                    startAnalysis("summary");
                                                                }}
                                                            >
                                                                <Icon
                                                                    name={"refresh"}
                                                                    size={"10"}
                                                                    color={"var(--color-SubBlack)"}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                {showSummaryStopButton && (
                                                    <Tooltip message={"작성중지"}>
                                                        <Button
                                                            onlyIcon
                                                            bgColor={"transparent"}
                                                            hoverBgColor={"var(--color-ButtonHover4)"}
                                                            onClick={(e) => {
                                                                stopAnalysis("summary");
                                                            }}
                                                        >
                                                            <Icon
                                                                name={"stop"}
                                                                size={"10"}
                                                                color={"var(--color-SubBlack)"}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </SummaryTitleSec>
                                            <SummaryTextWrap>
                                                {renderContent(showSummaryAiCursor, summaryText)}
                                            </SummaryTextWrap>
                                        </SummarySection>

                                        <SummarySection>
                                            <SummaryTitleSec>
                                                <SummaryTitleText>AI 비평</SummaryTitleText>
                                                {showReviewStartButton &&
                                                    bibliography?.file_id &&
                                                    user.plan_id !== Constants.FREE_PLAN && (
                                                        <Tooltip message={"새로쓰기"}>
                                                            <Button
                                                                onlyIcon
                                                                bgColor={"transparent"}
                                                                hoverBgColor={"var(--color-ButtonHover4)"}
                                                                onClick={(e) => {
                                                                    startAnalysis("review");
                                                                }}
                                                            >
                                                                <Icon
                                                                    name={"refresh"}
                                                                    size={"10"}
                                                                    color={"var(--color-SubBlack)"}
                                                                />
                                                            </Button>
                                                        </Tooltip>
                                                    )}
                                                {showReviewStopButton && (
                                                    <Tooltip message={"작성중지"}>
                                                        <Button
                                                            onlyIcon
                                                            bgColor={"transparent"}
                                                            hoverBgColor={"var(--color-ButtonHover4)"}
                                                            onClick={(e) => {
                                                                stopAnalysis("review");
                                                            }}
                                                        >
                                                            <Icon
                                                                name={"stop"}
                                                                size={"10"}
                                                                color={"var(--color-SubBlack)"}
                                                            />
                                                        </Button>
                                                    </Tooltip>
                                                )}
                                            </SummaryTitleSec>
                                            <SummaryTextWrap>
                                                {renderContent(showReviewAiCursor, reviewText)}
                                            </SummaryTextWrap>
                                        </SummarySection>
                                    </TabSummaryWrap>
                                )}

                                {/* 연관논문 탭의 컨텐츠 영역 */}
                                {tabActive === "relatedPapers" && (
                                    <TabRelpaperWrap>
                                        <RelPaperSection>
                                            <RelPapTitRow>
                                                <RelPaperTitleText>연관논문</RelPaperTitleText>
                                                <RelPapNumCircleText>
                                                    {relatedPapers?.similar_papers?.length || 0}
                                                </RelPapNumCircleText>
                                            </RelPapTitRow>
                                            <RelPaperTextWrap>
                                                {relatedPapers?.similar_papers?.length ? (
                                                    <PaperUl>
                                                        {relatedPapers?.similar_papers?.map((item, index) => (
                                                            <PaperLi key={index}>
                                                                <ReferenceDetailRelatedPaperItem paperInfo={item} />
                                                            </PaperLi>
                                                        ))}
                                                    </PaperUl>
                                                ) : (
                                                    <RelPapBlankView>
                                                        <RelPapBlankViewText>논문이 없습니다.</RelPapBlankViewText>
                                                    </RelPapBlankView>
                                                )}
                                            </RelPaperTextWrap>
                                        </RelPaperSection>
                                    </TabRelpaperWrap>
                                )}

                                {/* 서지정보 탭의 컨텐츠 영역 */}
                                {tabActive === "bibInfo" && (
                                    <TabBiblioInformationWrap>
                                        <TabReferenceBibInfo bibliography={bibliography} />
                                    </TabBiblioInformationWrap>
                                )}
                            </TabConContainer>
                        </ContentsLeftSide>

                        {/* 오른쪽 메뉴 */}
                        <ContentsRightSide>
                            <SubContentsBox>
                                {/* PDF */}
                                <PDFWrap>
                                    <PDFTitleText>PDF</PDFTitleText>
                                    <PDFInputWrap>
                                        <input
                                            ref={pdfInputRef}
                                            type="file"
                                            accept="application/pdf"
                                            style={{ display: "none" }}
                                            onChange={fileChangeHandler}
                                        />
                                        <PDFInput
                                            placeholder={
                                                fileStatus === "loading"
                                                    ? "업로드 중입니다."
                                                    : bibliography.file
                                                    ? decodeURI(bibliography.file?.split("/").pop())
                                                    : "PDF 파일을 등록해주세요."
                                            }
                                            onClick={(e) => {
                                                pdfInputRef.current.value = null;
                                                pdfInputRef.current.click();
                                            }}
                                            className={"disabled"}
                                            readOnly
                                            disabled={fileStatus === "loading"}
                                            style={{
                                                pointerEvents: fileStatus === "loading" ? "none" : "auto",
                                            }}
                                        />
                                        {bibliography.file && (
                                            <PDFButtonWrap>
                                                <Tooltip message="PDF 다운로드">
                                                    <Button
                                                        onlyIcon
                                                        buttonSize={"20px"}
                                                        bgColor={"var(--color-White)"}
                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                        borderRadius={"2px"}
                                                        disabled={!bibliography.file}
                                                        onClick={handleDownloadFile}
                                                    >
                                                        <Icon name={"download"} color={"var(--color-SubBlack)"} />
                                                    </Button>
                                                </Tooltip>
                                                <Tooltip message="PDF 삭제">
                                                    <Button
                                                        onlyIcon
                                                        buttonSize={"20px"}
                                                        bgColor={"var(--color-White)"}
                                                        hoverBgColor={"var(--color-ButtonHover4)"}
                                                        borderRadius={"2px"}
                                                        disabled={!bibliography.file}
                                                        onClick={handleDeleteFile}
                                                    >
                                                        <Icon name={"delete"} color={"var(--color-SubBlack)"} />
                                                    </Button>
                                                </Tooltip>
                                            </PDFButtonWrap>
                                        )}
                                    </PDFInputWrap>
                                    {bibliography.file ? (
                                        <Link
                                            to={
                                                "/reference/" + bibliography?.uuid + "/review/" + bibliography.file_uuid
                                            }
                                            target={"_blank"}
                                        >
                                            <PdfButton onlyText buttonText={"PDF 리뷰"} />
                                        </Link>
                                    ) : (
                                        <Tooltip width={"100%"} message="PDF 파일을 추가해주세요">
                                            <PdfButton onlyText disabled buttonText={"PDF 리뷰"} />
                                        </Tooltip>
                                    )}
                                    <Tooltip
                                        display={"block"}
                                        width={"100%"}
                                        message={!bibliography.file && "PDF가 있는 참고문헌만 제공하는 기능입니다"}
                                    >
                                        <PdfButton
                                            onlyText
                                            margin={"8px 0 0 0"}
                                            buttonText={"서지정보 가져오기"}
                                            disabled={!bibliography.file}
                                            onClick={(e) => {
                                                if (bibliography.file) {
                                                    setShowBibInfoLoadModal(!showBibInfoLoadModal);
                                                }
                                            }}
                                        ></PdfButton>
                                    </Tooltip>
                                    <DOIWrap>
                                        {bibliography.link?.doi && (
                                            <Link to={bibliography.link?.doi} target={"_blank"}>
                                                <LinkButton onlyText buttonText={"DOI"} />
                                            </Link>
                                        )}
                                        <Link
                                            to={
                                                "https://scholar.google.com/scholar?q=" +
                                                encodeURIComponent(bibliography.bib_json?.title)
                                            }
                                            target={"_blank"}
                                        >
                                            <LinkButton onlyText buttonText={"Google"} />
                                        </Link>
                                        {bibliography.link?.scienceon_content_url && (
                                            <Link to={bibliography.link?.scienceon_content_url} target={"_blank"}>
                                                <LinkButton onlyText buttonText={"ScienceON"} />
                                            </Link>
                                        )}
                                        {bibliography.link?.scienceon_fulltext_url && (
                                            <Link to={bibliography.link?.scienceon_fulltext_url} target={"_blank"}>
                                                <LinkButton onlyText buttonText={"PDF"} />
                                            </Link>
                                        )}
                                    </DOIWrap>
                                </PDFWrap>

                                {/* 리서치와 태그 */}
                                <ResearchTagWrap>
                                    <ReferenceResearchList
                                        showHeader={true}
                                        onModifyClick={(e) => setShowCitationEditModal(!showCitationEditModal)}
                                        bibliography={bibliography}
                                    />
                                    <ReferenceTagList
                                        showHeader={true}
                                        onModifyClick={(e) => setShowTagEditModal(!showTagEditModal)}
                                        bibliography={bibliography}
                                    />
                                </ResearchTagWrap>

                                {/* 메모 */}
                                <MemoWrap>
                                    <MemoTitleText>메모</MemoTitleText>
                                    <MemoTextarea
                                        ref={textareaRef}
                                        rows={1}
                                        name={"memo"}
                                        placeholder={"메모를 입력해주세요."}
                                        value={bibliography?.memo || ""}
                                        onChange={inputChangeHandler}
                                        onKeyDown={(e) => {
                                            genericEnterKeyDown(e, () => {
                                                if (e.shiftKey) {
                                                    inputChangeHandler(e);
                                                } else {
                                                    //blur 처리
                                                    e.preventDefault();
                                                    textareaRef.current.blur();
                                                }
                                            });
                                        }}
                                        onBlur={(e) => {
                                            updateBibliography("memo");
                                        }}
                                    />
                                </MemoWrap>

                                {/* 추가 / 최근 활동 날짜 */}
                                <DateWrap>
                                    <DateWrapper>
                                        <DateText>
                                            추가 {getDateFromString(bibliography.created_at)}{" "}
                                            {getTimeFromString(bibliography.created_at)}
                                        </DateText>
                                    </DateWrapper>
                                    <DateWrapper>
                                        <DateText>
                                            최근 활동 {getDateFromString(bibliography.updated_at)}{" "}
                                            {getTimeFromString(bibliography.updated_at)}
                                        </DateText>
                                    </DateWrapper>
                                </DateWrap>
                            </SubContentsBox>
                        </ContentsRightSide>
                    </ContentsWrapper>
                </WidthLimit>
            </Body>
            {showBibInfoLoadModal && (
                <AbstractModal
                    width={"720"}
                    modalTitle="서지정보 가져오기"
                    exitModal={(e) => {
                        setShowBibInfoLoadModal(false);
                        if (queueMetadataAnalyzeModal) {
                            setShowMetadataAnalyzeModal(true);
                        }
                    }}
                >
                    <ReferenceBibInfoLoadModal
                        bibliography={bibliography}
                        mutation={updateBibliographyMutation}
                        exitModal={(e) => {
                            setShowBibInfoLoadModal(false);
                            if (queueMetadataAnalyzeModal) {
                                setShowMetadataAnalyzeModal(true);
                            }
                        }}
                    />
                </AbstractModal>
            )}
            {showCitationEditModal && (
                <AbstractModal
                    width={480}
                    modalTitle="리서치 연결"
                    exitModal={(e) => setShowCitationEditModal(!showCitationEditModal)}
                >
                    <ReferenceCitationEditModal
                        exitModal={(e) => setShowCitationEditModal(!showCitationEditModal)}
                        citedResearches={bibliography.researches}
                        referenceId={bibliography?.id}
                    />
                </AbstractModal>
            )}
            {showTagEditModal && (
                <AbstractModal
                    width={480}
                    modalTitle="태그 적용"
                    exitModal={(e) => {
                        setShowTagEditModal(false);
                        if (queueMetadataAnalyzeModal) {
                            setShowMetadataAnalyzeModal(true);
                        }
                    }}
                >
                    <ReferenceTagEditModal
                        recommend={queueMetadataAnalyzeModal}
                        referenceId={bibliography.id}
                        referenceFile={bibliography.file}
                        selectedTagIds={bibliography.tag_mappings.map((tag) => tag.id)}
                        exitModal={(e) => {
                            setShowTagEditModal(false);
                            if (queueMetadataAnalyzeModal) {
                                setShowMetadataAnalyzeModal(true);
                            }
                        }}
                    />
                </AbstractModal>
            )}
            {showCitationStyleModal && (
                <AbstractModal
                    width={480}
                    height={240}
                    modalTitle="인용 스타일"
                    exitModal={(e) => setShowCitationStyleModal(false)}
                >
                    <ReferenceCitationStyleModal
                        exitModal={(e) => setShowCitationStyleModal(false)}
                        reference={bibliography}
                    />
                </AbstractModal>
            )}
            {showMetadataAnalyzeModal && (
                <AbstractModal
                    width={480}
                    modalTitle="메타데이터 분석"
                    exitModal={(e) => {
                        setShowMetadataAnalyzeModal(false);
                        setQueueMetadataAnalyzeModal(false);
                        startSummaryAndReview();
                    }}
                >
                    <ReferenceMetadataAnalyzeModal
                        onBibInfoLoadClick={(e) => {
                            setShowMetadataAnalyzeModal(false);
                            setQueueMetadataAnalyzeModal(true);

                            setShowBibInfoLoadModal(true);
                        }}
                        onTagRecommendClick={(e) => {
                            setShowMetadataAnalyzeModal(false);
                            setQueueMetadataAnalyzeModal(true);

                            setShowTagEditModal(true);
                        }}
                        exitModal={(e) => {
                            setShowMetadataAnalyzeModal(false);
                            setQueueMetadataAnalyzeModal(false);
                            startSummaryAndReview();
                        }}
                    />
                </AbstractModal>
            )}
        </LoginUserLayout>
    );
}

export default ReferenceDetailPage;
