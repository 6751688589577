import React, { useEffect, useState } from "react";

import styled from "styled-components";

import LoadingImage from "assets/png/others/LoadingImage.png";

import Alert from "components/atoms/alert/Alert";
import useLoading from "components/atoms/loading/useLoading";
import GeneralText from "components/atoms/text/GeneralText";
import TitleText from "components/atoms/text/TitleText";

import AbstractModalFooter from "components/page/modal/AbstractModalFooter";

import { useBibJsonUpdate } from "hooks/queries/useBibliographies";
import { usePaperMetadata } from "hooks/queries/usePapers";

import Constants from "utils/constants";

const Container = styled.div`
    width: 100%;
`;

const TabviewWrap = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`;

const FormContainer = styled.form`
    position: relative;
    width: 100%;
`;

const FormInner = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: calc(100% - 80px);
    min-height: calc(100vh - 400px);
    max-height: calc(100vh - 400px);

    ${Constants.BIG_HEIGHT_MQ} {
        min-height: calc(100vh - 560px);
        max-height: calc(100vh - 560px);
    }
`;

const InfoView = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 500px;
`;

const LoadingImageWrap = styled.div`
    margin: 24px 0 24px 0;
    width: 56px;
    height: 56px;
    background-image: url(${(props) => props.loadImg});
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
`;

const IndicatorWrap = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 40px 0;
    width: 120px;
`;

const Circle = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 6px;
    background-color: var(--color-Key);
`;

const ScrollWrap = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1;
    margin: 0;
    padding: 16px;
    max-height: calc(100vh - 440px);
    background-color: var(--color-Base1);
    border-radius: 8px;
    overflow: auto;
`;

const FormItemWrap = styled.div`
    display: flex;
    flex-direction: column;
    padding: 12px 0 12px 0;
    width: 100%;
    background-color: transparent;
    border-bottom: solid 1px var(--color-Line);

    &:first-child {
        padding: 0 0 12px 0;
    }

    &:last-child {
        padding: 12px 0 0 0;
        border-bottom: transparent;
    }
`;

const ItemLeft = styled.div`
    display: flex;
    align-items: center;
    margin: 0 8px 0 0;
    width: 140px;
    height: 24px;
`;

const ItemRight = styled.div`
    display: flex;
    align-items: flex-start;
    width: calc(100% - 148px);
`;

const FormRow = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin: 0 0 8px 0;
    width: 100%;

    &:last-child {
        margin: 0;
    }
`;

const NameWrap = styled.div`
    position: relative;
    top: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 0 8px 0;
    padding: 0 0 0 8px;
    border-left: solid 2px var(--color-ButtonHover3);
    overflow-wrap: anywhere;
`;

const ErrorMessage = styled.div`
    margin-top: 4px;
    color: var(--color-Warn);
    font-size: 10px;
    font-weight: 400;
`;

const FormLabel = styled.label`
    margin: 0 0 0 8px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-Black);
`;

const FormButton = styled.button`
    padding: 0 8px;
    flex: 1;
    min-height: 24px;
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    color: ${(props) => (props.checked ? "var(--color-Black)" : "var(--color-Grey1)")};
    background-color: ${(props) => (props.checked ? "#d6eae6" : "var(--color-White)")};
    border-radius: 4px;
    border: solid 1px ${(props) => (props.checked ? "var(--color-Black)" : "var(--color-Grey1)")};
    text-align: left;
    line-break: anywhere;

    &:hover {
        color: var(--color-Black);
        border: solid 1px var(--color-Black);
    }
`;

const LabelWrap = styled.div`
    display: flex;
    align-items: center;
    height: 24px;
    margin: 0 8px 0 0;
`;

const TabViewSection = styled.div`
    margin: 24px 0 0 0;
    width: 100%;
    height: 100%;
    max-height: calc(100% - 151px);
    overflow: auto;
`;

function ReferenceBibInfoLoadModal(props) {
    const [isPaperMetadataAnalyzing, setIsPaperMetadataAnalyzing] = useState(false);

    const paperMetadataQuery = usePaperMetadata({ reference_id: props.bibliography.id });

    const bibJsonMutation = useBibJsonUpdate();
    const [error, setError] = useState({});
    const { setLoading } = useLoading();
    const [metaJson, setMetaJson] = useState({});

    useEffect(() => {
        return () => {
            paperMetadataQuery.remove();
        };
    }, []);

    useEffect(() => {
        if (paperMetadataQuery.data) {
            let temp_dict = {};
            for (let key in props.bibliography.bib_json) {
                let temp_array = [];

                // 원본 json 정보 저장
                temp_array.push({
                    type: "origin",
                    value: props.bibliography.bib_json[key],
                    checked: true,
                });
                if (paperMetadataQuery.data.data.bib_json[key] !== null) {
                    // 파일에서 가져온 bib json 데이터에서 원본 json 데이터와 겹치는 부분이 있으면 값을 저장
                    temp_array.push({
                        type: "imported",
                        value: paperMetadataQuery.data.data.bib_json[key],
                        checked: false,
                    });
                } else {
                    // 파일에서 가져온 bib json 데이터에서 원본 json 데이터와 겹치는 부분이 없으면 키만 저장
                    temp_array.push({ type: "imported", value: "", checked: false });
                }
                temp_dict[key] = temp_array;
            }
            for (let key in paperMetadataQuery.data.data.bib_json) {
                if (!Object.keys(props.bibliography.bib_json).includes(key)) {
                    let temp_array = [];
                    temp_array.push({ type: "origin", value: "", checked: true });
                    temp_array.push({
                        type: "imported",
                        value: paperMetadataQuery.data.data.bib_json[key],
                        checked: false,
                    });
                    temp_dict[key] = temp_array;
                }
            }
            let dict = sortBibJson(temp_dict);
            setMetaJson(dict);
        }
    }, [paperMetadataQuery.data]);

    const sortBibJson = (bibJson) => {
        // 1. entry_type 2. citation_key 3. etc 순서로 변경한다.
        const sortedBibJson = {};
        const sortedKeys = Object.keys(bibJson).sort((a, b) => {
            if (a === "entry_type") {
                return -1;
            } else if (b === "entry_type") {
                return 1;
            } else if (a === "citation_key") {
                return -1;
            } else if (b === "citation_key") {
                return 1;
            } else {
                return 0;
            }
        });
        sortedKeys.forEach((key) => {
            sortedBibJson[key] = bibJson[key];
        });
        return sortedBibJson;
    };

    useEffect(() => {
        setIsPaperMetadataAnalyzing(paperMetadataQuery.isFetching);
    }, [paperMetadataQuery.isFetching]);

    const handleMetaChecked = (field) => {
        let temp_dict = { ...metaJson };
        temp_dict[field][0].checked = !temp_dict[field][0].checked;
        temp_dict[field][1].checked = !temp_dict[field][1].checked;
        setMetaJson(temp_dict);
    };

    const onSubmit = () => {
        let jsonData = {};
        Object.keys(metaJson).map((field, index) => {
            let value = metaJson[field][0].checked ? metaJson[field][0].value : metaJson[field][1].value;
            if (value) {
                jsonData[field] = value;
            }
        });

        bibJsonMutation.mutate(
            {
                id: props.bibliography.id,
                bib_json: jsonData,
            },
            {
                onMutate: (variables) => {
                    setLoading(true);
                },
                onSettled: (data, variables, context) => {
                    setLoading(false);
                },
                onSuccess: (data, variables, context) => {
                    setError({});
                    props.exitModal();
                    Alert("info", "성공", "참고문헌이 수정되었습니다.");
                },
                onError: (error, variables, context) => {
                    console.log(error.response.data);
                    setError(error.response.data);
                    if (error.response.data.bib_json) {
                        Alert("warn", "에러", error.response.data.bib_json);
                    } else {
                        Alert("warn", "에러", `아래 필드를 확인해주세요. \n ${Object.keys(error.response.data)}`);
                    }
                },
            },
        );
    };

    return (
        <Container>
            <GeneralText size={"regular"}>
                기존 서지정보와 PDF의 서지정보 중 원하는 항목을 선택할 수 있습니다.
                <br />
                선택한 항목은 참고문헌의 서지정보에 반영됩니다.
            </GeneralText>
            {isPaperMetadataAnalyzing ? (
                <InfoView>
                    <LoadingImageWrap loadImg={LoadingImage} />
                    <GeneralText size={"regular"} margin={"0 0 32px 0"} color={"var(--color-Black)"}>
                        {"PDF 파일을 분석하고 있습니다."}
                    </GeneralText>
                    <IndicatorWrap>
                        <Circle className="circle1" />
                        <Circle className="circle2" />
                        <Circle className="circle3" />
                        <Circle className="circle4" />
                        <Circle className="circle5" />
                    </IndicatorWrap>
                </InfoView>
            ) : (
                <TabViewSection>
                    <TabviewWrap>
                        <FormContainer>
                            <FormInner>
                                <ScrollWrap>
                                    {Object.keys(metaJson).map((field, index) => {
                                        return (
                                            <FormItemWrap key={index}>
                                                {metaJson[field].map((obj, idx) => {
                                                    let label = obj.type === "origin" ? `${field}` : `${field}`;
                                                    let option = obj.type === "origin" ? `기존` : `추천`;

                                                    return (
                                                        <FormRow key={idx + obj.type}>
                                                            <ItemLeft
                                                                style={obj.type === "origin" ? {} : { display: "none" }}
                                                            >
                                                                <NameWrap>
                                                                    <TitleText size={"subRegular"}>{label}</TitleText>
                                                                    {error[field] && (
                                                                        <ErrorMessage>{error[field]}</ErrorMessage>
                                                                    )}
                                                                </NameWrap>
                                                            </ItemLeft>
                                                            <ItemRight>
                                                                <LabelWrap>
                                                                    <FormLabel>{option}</FormLabel>
                                                                </LabelWrap>
                                                                <FormButton
                                                                    type={"button"}
                                                                    checked={obj.checked}
                                                                    onClick={() => handleMetaChecked(field)}
                                                                >
                                                                    {obj.value}
                                                                </FormButton>
                                                            </ItemRight>
                                                        </FormRow>
                                                    );
                                                })}
                                            </FormItemWrap>
                                        );
                                    })}
                                </ScrollWrap>
                            </FormInner>
                        </FormContainer>

                        <AbstractModalFooter
                            leftBtnText={"취소"}
                            rightBtnText={"적용"}
                            leftOnClick={props.exitModal}
                            rightOnClick={() => onSubmit()}
                        />
                    </TabviewWrap>
                </TabViewSection>
            )}
        </Container>
    );
}

export default ReferenceBibInfoLoadModal;
